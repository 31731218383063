import React from 'react';


function Footer(props) {
  const { event = { data: {} } } = props;
  const { language } = event.data;

  if (language === 'EN') {
    return (
      <div className="px-5 md:px-0 text-sm">
        <div className="md:flex justify-between py-10 max-w-2xl mx-auto border-t-2 items-center">
          <div className="w-48 mx-auto md:mx-0">
            <img src="/images/FocusAcademy-logo.png" alt="FocusAcademy logo" />
          </div>
          <div className="px-5 md:px-0 md:pl-10 mt-5 md:mt-0">
            <div className="flex">
              <div className="font-bold mr-2">Website:</div>
              <div>www.focusacademy.com</div>
            </div>
            <div className="flex">
              <div className="font-bold mr-2">Email:</div>
              <div>info@focusacademy.com</div>
            </div>
            <div className="flex">
              <div className="font-bold mr-2">Phone number:</div>
              <div>+31 (0) 20 261 0076</div>
            </div>
            <div className="mt-3">
              Oudeschans 83 1011 KW Amsterdam
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="px-5 md:px-0 text-sm">
      <div className="md:flex justify-between py-10 max-w-2xl mx-auto border-t-2 items-center">
        <div className="w-48 mx-auto md:mx-0">
          <img src="/images/FocusAcademy-logo.png" alt="FocusAcademy logo" />
        </div>
        <div className="px-5 md:px-0 md:pl-10 mt-5 md:mt-0">
          <div className="flex">
            <div className="font-bold mr-2">Website:</div>
            <div>www.focusacademy.com</div>
          </div>
          <div className="flex">
            <div className="font-bold mr-2">Email:</div>
            <div>info@focusacademy.com</div>
          </div>
          <div className="flex">
            <div className="font-bold mr-2">Telefoon:</div>
            <div>+31 (0) 20 261 0076</div>
          </div>
          <div className="mt-3">
            Herengracht 66-1 1015 BR Amsterdam
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;