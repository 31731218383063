import React from 'react';
import Header from './Event/Header';
import Footer from './Event/Footer';


function ErrorBrowser() {

  return (
    <div>
      <Header />

      <div className="min-h-screen">
        <div class="my-10 text-center p-5">
          Helaas, er is iets misgegaan..

          <div class="my-2 max-w-xl mx-auto">
            Voor de livestream heb je één van de volgende browsers nodig: <br/> <span className="font-bold">Chrome (55+), Firefox (53+), Safari (12.1+) of Microsoft Edge (42+).</span>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>

    </div>
  );
}

export default ErrorBrowser;
