import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Chat from './Chat';
import MediaPlayer from './MediaPlayer';
import Poll from './Poll';
import MostPopularMessages from './MostPopularMessages';
import HiddenMessages from './HiddenMessages';
import Sticky from './Sticky';
import FocusPlan from './FocusPlan';
import { updateEventAttendeeMinQA, updateEventAttendeeMinFocusPlan, updateEventAttendeeFingerprint } from '../../database/events';
import AmazonPlayer from './AmazonPlayer';


function LiveNow(props) {
  const { event, attendee, isAdmin } = props;
  const { eventKey } = event.data;

  const attendeeKey = attendee && attendee.data && attendee.data.attendeeKey
    ? attendee.data.attendeeKey
    : null;

  const attendeeMinQA = attendee && attendee.data && attendee.data.minQA
    ? attendee.data.minQA
    : null;
  
  const attendeeMinFocusPlan = attendee && attendee.data && attendee.data.minFocusPlan
    ? attendee.data.minFocusPlan
    : null;

  const pageStatus = event && event.data && event.data.pageStatus
    ? event.data.pageStatus
    : 'liveSoon';

  const chatStatus = event && event.data && event.data.chatStatus
    ? event.data.chatStatus
    : 'off';

  const qaStatus = event && event.data && event.data.qaStatus
    ? event.data.qaStatus
    : 'off';

  const pollStatus = event && event.data && event.data.pollStatus
    ? event.data.pollStatus
    : 'off';

  const pageHTMLNow = event && event.data && event.data.pageHTMLs && event.data.pageHTMLs['liveNow']
    ? event.data.pageHTMLs['liveNow']
    : null;

  const pageHTMLDone = event && event.data && event.data.pageHTMLs && event.data.pageHTMLs['liveDone']
    ? event.data.pageHTMLs['liveDone']
    : null;

  const focusPlanRender = event && event.data && event.data.focusPlanRender
    ? event.data.focusPlanRender
    : 'off';

  const mediaPlayerType = event && event.data && event.data.mediaPlayerType
    ? event.data.mediaPlayerType
    : 'youtube';


  // Save login
  useEffect(() => {
    if (!eventKey || !attendeeKey) {
      return;
    }
    
    FingerprintJS.load().then(fp => {
      // The FingerprintJS agent is ready.
      // Get a visitor identifier when you'd like to.
      fp.get().then(result => {
        // This is the visitor identifier:
        const visitorId = result.visitorId;

        const fingerprint = {
          visitorId: visitorId,
          datetime: Date.now(),
        };
    
        updateEventAttendeeFingerprint({
          eventKey,
          attendeeKey,
          fingerprint,
        });
      });
    });
  }, [eventKey, attendeeKey]);


  return (
    <div>
      <div className="flex flex-col justify-center py-5">
        <div className="lg:flex w-full">

          <div className="flex-grow p-5 mx-auto lg:max-w-4/5">
            {
              pageStatus === 'liveNow'
              && mediaPlayerType === 'youtube'
              && (
                <MediaPlayer event={event} />
              )
            }

            {
              pageStatus === 'liveNow'
              && mediaPlayerType === 'amazon'
              && (
                <AmazonPlayer event={event} />
              )
            }
          </div>

          {
            pageStatus === 'liveDone'
            && (
              <div className="flex-grow p-5 mx-auto lg:max-w-4/5">
                <div className="relative aspect-ratio-box">
                  <div className="p-5">
                    <div>
                      {
                        !!pageHTMLDone
                        && (
                          <div dangerouslySetInnerHTML={{__html: pageHTMLDone }} />
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
          }

          {
            !(
              pageStatus === 'liveDone'
              && chatStatus === 'off'
              && pollStatus === 'off'
            ) && (
              <div
                className="lg:w-1/3 flex-shrink-0 h-112 lg:h-auto flex flex-col p-5 lg:pl-0 mx-auto lg:max-w-4/5"
                style={{ maxHeight: '90vh' }}
              >
                <Poll event={event} attendee={attendee} isAdmin={isAdmin} />
                <Sticky event={event} attendee={attendee} isAdmin={isAdmin} />
                <Chat event={event} attendee={attendee} isAdmin={isAdmin} />
              </div>
            )
          }

        </div>
      </div>


      <div className="lg:flex">
        {
          (
            focusPlanRender === 'on'
            || !!isAdmin
          )
          && (
            <div className="flex-grow p-5 mx-auto lg:max-w-4/5">
              <div className="bg-gray-900 p-3 flex">
                <h3 className="font-bold text-white flex-grow">Focus Plan</h3>
                <div>
                  <button
                    className="inline-block bg-gray-700 hover:bg-gray-600 text-gray-900 rounded px-1 text-sm leading-normal focus:outline-none"
                    onClick={() => {
                      if (!eventKey || !attendeeKey) {
                        return;
                      }
                      updateEventAttendeeMinFocusPlan({ eventKey, attendeeKey, minFocusPlan: !attendeeMinFocusPlan })
                    }}
                  >
                    {
                      !attendeeMinFocusPlan
                      && <i className="far fa-eye-slash"></i>
                    }
                     {
                      !!attendeeMinFocusPlan
                      && <i className="far fa-eye"></i>
                    }
                  </button>
                </div>
              </div>
              {
                !attendeeMinFocusPlan
                && (
                  <div className="bg-gray-500 p-3">
                    <FocusPlan event={event} attendee={attendee} isAdmin={isAdmin} />
                  </div>
                )
              }
            </div>
          )
        }

        {
          (
            qaStatus !== 'off'
            || !!isAdmin
          )
          && (
            <div className="lg:w-1/3 flex-shrink-0 h-112 lg:h-auto p-5 lg:pl-0 mx-auto lg:max-w-4/5">
              
              <div className="bg-gray-900 p-3 flex">
                <h3 className="font-bold text-white flex-grow">Q & A</h3>
                <div>
                  <button
                    className="inline-block bg-gray-700 hover:bg-gray-600 text-gray-900 rounded px-1 text-sm leading-normal focus:outline-none"
                    onClick={() => {
                      if (!eventKey || !attendeeKey) {
                        return;
                      }
                      updateEventAttendeeMinQA({ eventKey, attendeeKey, minQA: !attendeeMinQA })
                    }}
                  >
                    {
                      !attendeeMinQA
                      && <i className="far fa-eye-slash"></i>
                    }
                     {
                      !!attendeeMinQA
                      && <i className="far fa-eye"></i>
                    }
                  </button>
                </div>
              </div>

              {
                !attendeeMinQA
                && (
                  <div className="flex-grow bg-gray-800">
                    <div className="p-3">
                      <MostPopularMessages event={event} attendee={attendee} isAdmin={isAdmin} />
                    </div>
                  </div>
                )
              }

              {
                !!isAdmin
                && (
                  <div className="mt-5">
                    <h3 className="bg-gray-900 text-white p-3 font-bold">Hidden from Q&A</h3>
                    <div className="flex-grow bg-gray-800">
                      <div className="p-3">
                      <HiddenMessages event={event} attendee={attendee} isAdmin={isAdmin} />
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          )
        }


      </div>

      <div className="p-5 mt-48">
        {
          !!pageHTMLNow
          && (
            <div dangerouslySetInnerHTML={{__html: pageHTMLNow }} />
          )
        }
      </div>
    </div>
  );
}

LiveNow.defaultProps = {
  attendee: null,
}


LiveNow.propTypes = {
  event: PropTypes.object.isRequired,
  attendee: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
};

export default LiveNow;
