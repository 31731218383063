import React from 'react';
import { useParams } from "react-router-dom";
import { useEvent, useEventAttendee } from '../../database/events';
import { useUser, login } from '../../database/auth';
import LiveNow from './LiveNow';
import Header from './Header';
import LiveSoon from './LiveSoon';
import Footer from './Footer';
import ErrorPage from '../ErrorPage';
import AdminQuickControl from './AdminQuickControl';
import RegistrationForm from './RegistrationPage';
import RegistrationFormAnonymous from './RegistrationFormAnonymous';


function Event() {
  const { eventKey, attendeeKey } = useParams();
  const user = useUser();
  const event = useEvent({ eventKey });
  const attendee = useEventAttendee({ eventKey, attendeeKey });
  const isAdmin = attendeeKey === 'admin';


  // If event is loading, show spinner
  if (event && event.loading === true) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" />
      </div>
    );
  }

  // If attendee is loading, show spinner
  if (attendee && attendee.loading === true) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" />
      </div>
    );
  }

  // If no event is found, redirect
  if (!event || !event.data) {
    return (
      <ErrorPage />
    );
  }

  // If no admin, no user, and has open registration - show registration form
  if (!isAdmin && !attendee.data) {

    console.log(event.data.openRegistration);

    if (
      event
      && event.data
      && event.data.openRegistration
      && event.data.openRegistration === 'on'
    ) {
      return (
        <>
          <div className="min-h-screen flex flex-col">

            <div className="flex-grow">
              <RegistrationForm event={event} />
            </div>

            <div>
              <Footer />
            </div>

          </div>
        </>
      )
    }

    if (
      event
      && event.data
      && event.data.openRegistration
      && event.data.openRegistration === 'anonymous'
    ) {
      return (
        <>
          <div className="min-h-screen flex flex-col">

            <div className="flex-grow">
              <RegistrationFormAnonymous event={event} />
            </div>

            <div>
              <Footer />
            </div>

          </div>
        </>
      )
    }

    // If no admin, no user, and has NO open registration - show registration form
    return (
      <ErrorPage />
    );
  }


  // If attendee is admin and not logged in, show login button
  if (isAdmin && !user) {
    return (
      <div className="flex content-center justify-center py-20">
        <div>
          <h2 className="mb-3 font-bold text-center text-gray-700">Admin login</h2>
          <button
            type="button"
            className="bg-indigo-700 text-white py-2 px-4 rounded focus:outline-none"
            onClick={() => {
              login();
            }}
          >
            Login with Google
          </button>
        </div>
      </div>
    )
  }



  // Page data
  const pageStatus = event && event.data && event.data.pageStatus
    ? event.data.pageStatus
    : 'liveSoon';


  return (
    <>
      <div className="min-h-screen flex flex-col">
        <Header event={event} attendee={attendee} isAdmin={isAdmin} />

        {
          !!isAdmin
          && (
            <AdminQuickControl event={event} />
          )
        }

        <div className="flex-grow">
          {
            pageStatus === 'liveSoon'
            && (
              <LiveSoon event={event} />
            )
          }

          {
            (
              pageStatus === 'liveNow'
              || pageStatus === 'liveDone'
            )
            && (
              <LiveNow event={event} attendee={attendee} isAdmin={isAdmin} />
            )
          }
        </div>

        <div>
          <Footer event={event} />
        </div>

      </div>
    </>
  );
}

export default Event;
