import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Event from './Event';
import ErrorPage from './ErrorPage';
import Admin from './Admin';
import FullScreenFocusPlan from './Event/FocusPlan/FullScreenFocusPlan';


function Pages() {
  return (
    <Router>
      <Switch>
      <Route path="/e/:eventKey/u/:attendeeKey/focus-plan"  component={FullScreenFocusPlan} />
        <Route path="/e/:eventKey/u/:attendeeKey" component={Event} />
        <Route path="/e/:eventKey" component={Event} />

        <Route exact path="/admin" component={Admin} />
        <Route path="/admin/e/:eventKey" component={Admin} />
        
        <Route path="*" component={ErrorPage} />
      </Switch>

    </Router>
  );
}

export default Pages;
