import React, { useState, useRef, useEffect, useCallback } from 'react';
import twemoji from 'twemoji';
import { useUser } from '../../../database/auth';
import { addEventMessage, updateEventAttendee } from '../../../database/events';


function InputBar(props) {
  const { event, attendee, isAdmin } = props;
  const { eventKey, language } = event.data;
  const user = useUser();
  const [input, setInput] = useState('');
  const inputElement = useRef(null);
  const [sending, setSending] = useState();

  const userData = isAdmin
    ? {
      name: user.displayName,
      email: user.email || null,
      admin: true,
    }
    : {
      name: attendee.data.name,
      attendeeKey: attendee.data.attendeeKey,
      email: attendee.data.email || null,
      admin: false,
    }


  // Add message
  const addMessage = useCallback(
    ({ input }) => {
      if (!input) {
        return;
      }

      if (
        (isAdmin && (!user || !user.displayName)) // admin && admin data
        || (!isAdmin && (!attendee || !attendee.data || !attendee.data.name || !attendee.data.attendeeKey)) // no admin and attendee data
      ) {
        console.log('not enough data');
        return;
      }

      // Send message
      setSending(true);
      addEventMessage({ eventKey, userData, message: input })
        .then((result) => {
          setSending(false);
        })
        .catch((error) => {
          console.log("Error document:", error);
          setSending(false);
        });
      setInput('');
      inputElement.current.focus();
    },
    [attendee, eventKey, isAdmin, user, userData],
  );

  // Listen to Enter key
  useEffect(() => {
    const upHandler = (event) => {
      if (event.keyCode !== 13) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      addMessage({ input });
    }
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keyup', upHandler);
    };
  }, [input, addMessage]);


  if (!userData.name) {
    return (
      <>
        <div className="flex-shrink-0 border-t border-gray-700">
          <div className="flex leading-none bg-red-300 px-5 py-4 text-gray-800 ">
            {
              !!language
              && language === 'EN'
              && 'You are still anonymous. Please enter a name below, to join in the chat.'
            }
            {
              !!language
              && language === 'NL'
              && (
                'Je bent nog anoniem. Vul hieronder een naam in, om mee te doen in de chat.'
              )
            }
          </div>
        </div>
        <div className="flex-shrink-0">
          <div className="flex leading-none bg-gray-700">
            <div className="flex-grow bg-gray-700">
              <input
                value={input}
                type="text"
                className="focus:outline-none bg-gray-700 h-12 p-2 px-3 text-sm text-white block w-full leading-normal appearance-none"
                ref={inputElement}
                onChange={e => setInput(e.target.value)}
                placeholder={
                  language === 'EN'
                    ? 'Enter your name here..'
                    : 'Vul hier je naam in..'
                }
              />
            </div>
            <div className="flex items-center">
              <button
                type="button"
                className="h-12 px-5 bg-gray-800 text-gray-200 flex justify-center items-center content-center focus:outline-none"
                onClick={() => {
                  if (!input || typeof input !== 'string') {
                    return;
                  }

                  const newUserData = {
                    ...userData,
                    name: input
                  };
                  updateEventAttendee({ eventKey, userData: newUserData})
                    .then((result) => {
                      setSending(false);
                      setInput('');
                      inputElement.current.focus();
                    })
                    .catch((error) => {
                      console.log("Error document:", error);
                      setSending(false);
                      setInput('');
                      inputElement.current.focus();
                    });
                }}
              >
                {
                  !!sending
                  && (
                    <i className="fas fa-cog fa-spin text-gray-700 text-lg" />
                  )
                }
                {
                  !!language
                  && language === 'EN'
                  && 'set name'
                }
                {
                  !!language
                  && language === 'NL'
                  && (
                    'naam instellen'
                  )
                }
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="flex-shrink-0 border-t border-gray-700">
        <div className="flex leading-none bg-gray-700 border-b border-gray-800 text-lg">
          <button
            type="button"
            onClick={(event) => {
              if (event.detail === 0) {
                return;
              }
              const newInput = input.concat(':thumbs-up:');
              setInput(newInput);
            }}
            className="p-2 flex-grow text-center hover:bg-green-500 focus:outline-none"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: twemoji.parse('👍', {
                  folder: 'svg',
                  ext: '.svg'
                })
              }}
            />
          </button>
          <button
            onClick={(event) => {
              if (event.detail === 0) {
                return;
              }
              const newInput = input.concat(':thumbs-down:');
              setInput(newInput);
            }}
            className="p-3 flex-grow text-center hover:bg-green-500 focus:outline-none"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: twemoji.parse('👎', {
                  folder: 'svg',
                  ext: '.svg'
                })
              }}
            />
          </button>
          <button
            onClick={(event) => {
              if (event.detail === 0) {
                return;
              }
              const newInput = input.concat(':clapping-hands:');
              setInput(newInput);
            }}
            className="p-3 flex-grow text-center hover:bg-green-500 focus:outline-none"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: twemoji.parse('👏', {
                  folder: 'svg',
                  ext: '.svg'
                })
              }}
            />
          </button>
          <button
            onClick={(event) => {
              if (event.detail === 0) {
                return;
              }
              const newInput = input.concat(':folded-hands:');
              setInput(newInput);
            }}
            className="p-3 flex-grow text-center hover:bg-green-500 focus:outline-none"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: twemoji.parse('🙏', {
                  folder: 'svg',
                  ext: '.svg'
                })
              }}
            />
          </button>
          <button
            onClick={(event) => {
              if (event.detail === 0) {
                return;
              }
              const newInput = input.concat(':waving-hand:');
              setInput(newInput);
            }}
            className="p-3 flex-grow text-center hover:bg-green-500 focus:outline-none"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: twemoji.parse('👋', {
                  folder: 'svg',
                  ext: '.svg'
                })
              }}
            />
          </button>
          <button
            onClick={(event) => {
              if (event.detail === 0) {
                return;
              }
              const newInput = input.concat(':brain:');
              setInput(newInput);
            }}
            className="p-3 flex-grow text-center hover:bg-green-500 focus:outline-none"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: twemoji.parse('🧠', {
                  folder: 'svg',
                  ext: '.svg'
                })
              }}
            />
          </button>
        </div>
      </div>
      <div className="flex-shrink-0">
        <div className="flex leading-none bg-gray-700">
          <div className="flex-grow bg-gray-700">
            <input
              value={input}
              type="text"
              className="focus:outline-none bg-gray-700 h-12 p-2 px-3 text-sm text-white block w-full leading-normal appearance-none"
              ref={inputElement}
              onChange={e => setInput(e.target.value)}
              placeholder={
                !!userData && !!userData.name
                  ? language === 'EN' ? `Hi ${userData.name}, Write a message here..` : `Hi ${userData.name}, schrijf hier een bericht..`
                  : language === 'EN' ? 'Write a message..' : 'Schrijf een bericht..'
              }
            />
          </div>
          <div className="flex items-center">
            <button
              type="button"
              className="w-12 h-12 bg-green-500 flex justify-center items-center content-center focus:outline-none"
              onClick={() => {
                addMessage({ input });
              }}
            >
              {
                !!sending
                && (
                  <i className="fas fa-cog fa-spin text-gray-700 text-lg" />
                )
              }
              {
                !sending
                && (
                  <i className="fas fa-paper-plane text-gray-700 text-lg" />
                )
              }
            </button>
          </div>
        </div>
      </div>
    </>
  );
}


export default InputBar;
