import React, { useState } from 'react';
import { sendFocusPlan } from '../../../database/events';

function EmailModal(props) {
  const { setEmailModal, attendee, focusPlan } = props;
  const attendeeData = attendee && attendee.data ? attendee.data : {};
  const [checkbox, setCheckbox] = useState(false);
  const [email, setEmail] = useState(attendeeData.email || null);
  const [status, setStatus] = useState();

  // Modal stuff
  const modalNode = React.useRef();
  document.body.classList.add('overflow-hidden');
  React.useEffect(() => {
    const handleClick = (e) => {
      if (modalNode.current.contains(e.target)) {
        return;
      }
      document.body.classList.remove('overflow-hidden');
      setStatus(null);
      setEmailModal(false);
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.body.classList.remove('overflow-hidden');
    };
  }, [setEmailModal]);

  return (
    <div
      className="fixed overflow-auto w-full h-full inset-0 z-50 flex flex-col justify-end xl:justify-center items-center xl:items-center px-10"
      style={{ background: 'rgba(0,0,0,0.7)' }}
    >
      <div
        ref={modalNode}
        className="rounded-lg shadow w-full xl:w-1/2 mx-auto"
      >
        <div className="bg-blue-700 text-white rounded-t-md p-5 text-lg font-bold">
          E-mail your Focus Plan
        </div>
        <div className="bg-white p-5 text-sm xl:text-base rounded-b-md">

          {
            !status
            && (
              <div>

                <div className="">
                  <span className="font-bold">Email:</span>
                  <input
                    type="text"
                    name="email_address"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    autocomplete="email"
                    className="mt-1 text-gray-800 block w-full px-2 py-2 sm:text-sm border-2 border-gray-600 rounded focus:outline-none"
                    placeholder="Enter your email"
                  />
                </div>

                <div className="mt-3">
                  <button
                    type="button"
                    className="focus:outline-none block text-left flex items-center"
                    onClick={() => {
                      setCheckbox(!checkbox);
                    }}
                  >
                    {
                      !!checkbox
                      && <i class="fas fa-check-square text-xl flex-shrink-0"></i>
                    }
                    {
                      !checkbox
                      && <i class="far fa-square text-xl flex-shrink-0"></i>
                    }
                    <span className="ml-2 flex-grow">
                      Update me about the development of the Focus Plan platform.
                    </span>
                  </button>
                </div>

                <div className="mt-8 flex justify-end">
                  <button
                    type="button"
                    className="focus:outline-none bg-green-500 hover:bg-green-600 rounded text-gray-700 px-3 py-2"
                    onClick={() => {
                      setStatus('sending');
                      sendFocusPlan({ email, optin: checkbox, focusPlan })
                        .then(() => {
                          setStatus('success');
                        })
                        .catch(() => {
                          setStatus('error');
                        })
                    }}
                  >
                    Send <i className="ml-2 fas fa-paper-plane"></i>
                  </button>
                </div>

              </div>
            )
          }

          {
            status === 'sending'
            && (
              <div className="text-center">
                Sending e-mail <i className="ml-2 fas fa-cog fa-spin" />
              </div>
            )
          }

          {
            status === 'success'
            && (
              <div className="text-center">
                Success! We have send your Focus Plan to your e-mail.
              </div>
            )
          }

          {
            status === 'error'
            && (
              <div className="text-center">
                Oh no! Something went wrong. Please ask <a className="border-b-2 text-blue-700 font-bold" href="mailto:oscar@focusacademy.com">oscar@focusacademy.com</a> to fix this issue.
              </div>
            )
          }
        </div>
      </div>
      <div className="w-full p-5 flex xl:hidden justify-center text-white text-lg">
        <i className="far fa-times"></i>
      </div>
    </div>
  );
}

export default EmailModal;
