import React from 'react';
import twemoji from 'twemoji';
import InputBar from './InputBar';
import Item from './Item';


function CL(props) {
  const { event, cl, attendeeKey, focusPlan } = props;
  const { language } = event.data;

  console.log(language)

  let title = 'Engagement <span class="text-2xl ml-2">🔥</span>';

  if (cl === 'energy') {
    title = 'Energy <span class="text-2xl ml-2">⚡</span>';
  }
  if (cl === 'energy' && language === 'NL') {
    title = 'Energie <span class="text-2xl ml-2">⚡</span>';
  }

  if (cl === 'internal') {
    title = 'Internal stimuli <span class="text-2xl ml-2">💭</span>';
  }
  if (cl === 'internal' && language === 'NL') {
    title = 'Interne stimuli <span class="text-2xl ml-2">💭</span>';
  }

  if (cl === 'external') {
    title = 'External stimuli <span class="text-2xl ml-2">📣</span>';
  }
  if (cl === 'external' && language === 'NL') {
    title = 'Externe stimuli <span class="text-2xl ml-2">📣</span>';
  }

  const titleWithEMojis = twemoji.parse(title, {
    folder: 'svg',
    ext: '.svg'
  });

  return (
    <div>
      <div className="font-bold mb-2 text-gray-800 text-lg">
        <span
          className="flex items-center"
          dangerouslySetInnerHTML={{
            __html: titleWithEMojis,
          }}
        />
      </div>

      {
        // Challenges
      }
      <div className="bg-gray-300 rounded shadow mb-3">
        <div className="font-bold p-2">
          {
            language !== 'NL'
            && 'Challenges'
          }
          {
            language === 'NL'
            && 'Uitdagingen'
          }
        </div>
        {
          !!focusPlan[cl]
          && !!focusPlan[cl].challenges
          && !!focusPlan[cl].challenges.length
          && (
            <div className="p-2 border-t border-gray-400">
              {
                focusPlan[cl].challenges.map((item, itemKey) => {
                  return (
                    <Item
                      key={`item_${itemKey}`}
                      event={event}
                      attendeeKey={attendeeKey}
                      cl={cl}
                      category="challenges"
                      focusPlan={focusPlan}
                      itemKey={itemKey}
                      item={item}
                    />
                  )
                })
              }
            </div>
          )
        }
        <InputBar event={event} attendeeKey={attendeeKey} cl={cl} category="challenges" focusPlan={focusPlan} />
      </div>

      {
        // Solutions
      }
      <div className="bg-gray-300 rounded shadow mb-3">
        <div className="font-bold p-2">
        {
            language !== 'NL'
            && 'Solutions'
          }
          {
            language === 'NL'
            && 'Oplossingen'
          }
        </div>
        {
          !!focusPlan[cl]
          && !!focusPlan[cl].solutions
          && !!focusPlan[cl].solutions.length
          && (
            <div className="p-2 border-t border-gray-400">
              {
                focusPlan[cl].solutions.map((item, itemKey) => {
                  return (
                    <Item
                      key={`item_${itemKey}`}
                      event={event}
                      attendeeKey={attendeeKey}
                      cl={cl}
                      category="solutions"
                      focusPlan={focusPlan}
                      itemKey={itemKey}
                      item={item}
                    />
                  )
                })
              }
            </div>
          )
        }
        <InputBar event={event} attendeeKey={attendeeKey} cl={cl} category="solutions" focusPlan={focusPlan} />
      </div>

      {
        // Tiny Habits
      }
      <div className="bg-gray-300 rounded shadow">
        <div className="font-bold p-2">Tiny Habits</div>
        {
          !!focusPlan[cl]
          && !!focusPlan[cl].habits
          && !!focusPlan[cl].habits.length
          && (
            <div className="p-2 border-t border-gray-400">
              {
                focusPlan[cl].habits.map((item, itemKey) => {
                  return (
                    <Item
                      key={`item_${itemKey}`}
                      event={event}
                      attendeeKey={attendeeKey}
                      cl={cl}
                      category="habits"
                      focusPlan={focusPlan}
                      itemKey={itemKey}
                      item={item}
                    />
                  )
                })
              }
            </div>
          )
        }
        <InputBar event={event} attendeeKey={attendeeKey} cl={cl} category="habits" focusPlan={focusPlan} />
      </div>
    </div>
  );
}

export default CL;
