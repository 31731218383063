import React from 'react';
import { useParams } from "react-router-dom";
import { useEvent, useEventAttendee } from '../../../database/events';
import { useUser, login } from '../../../database/auth';
import Header from '../Header';
import Footer from '../Footer';
import ErrorPage from '../../ErrorPage';
import RegistrationForm from '../RegistrationPage';
import FocusPlan from '.';


function FullScreenFocusPlan() {
  const { eventKey, attendeeKey } = useParams();
  const user = useUser();
  const event = useEvent({ eventKey });
  const attendee = useEventAttendee({ eventKey, attendeeKey });
  const isAdmin = attendeeKey === 'admin';


  // If event is loading, show spinner
  if (event && event.loading === true) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" />
      </div>
    );
  }

  // If attendee is loading, show spinner
  if (attendee && attendee.loading === true) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" />
      </div>
    );
  }

  // If no event is found, redirect
  if (!event || !event.data) {
    return (
      <ErrorPage />
    );
  }

  // If no admin, no user, and has open registration - show registration form
  if (!isAdmin && !attendee.data) {

    if (event && event.data && event.data.openRegistration && event.data.openRegistration === 'on') {
      return (
        <>
          <div className="min-h-screen flex flex-col">

            <div className="flex-grow">
              <RegistrationForm event={event} />
            </div>

            <div>
              <Footer />
            </div>

          </div>
        </>
      )
    }

    // If no admin, no user, and has NO open registration - show registration form
    return (
      <ErrorPage />
    );
  }


  // If attendee is admin and not logged in, show login button
  if (isAdmin && !user) {
    return (
      <div className="flex content-center justify-center py-20">
        <div>
          <h2 className="mb-3 font-bold text-center text-gray-700">Admin login</h2>
          <button
            type="button"
            className="bg-indigo-700 text-white py-2 px-4 rounded focus:outline-none"
            onClick={() => {
              login();
            }}
          >
            Login with Google
          </button>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="min-h-screen flex flex-col">
        <Header event={event} attendee={attendee} isAdmin={isAdmin} />

        <div className="flex-grow">
          <div className="p-5 bg-gray-500">
            <FocusPlan event={event} attendee={attendee} isAdmin={isAdmin} />
          </div>
        </div>

        <div>
          <Footer event={event} />
        </div>

      </div>
    </>
  );
}

export default FullScreenFocusPlan;
