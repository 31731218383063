import React from 'react';
import Bowser from "bowser";
import Pages from './Pages';
import { useAuth, userContext } from '../database/auth';
import ErrorBrowser from './ErrorBrowser';

const browser = Bowser.getParser(window.navigator.userAgent);


function App() {
  const {
    initializing,
    user,
  } = useAuth();

  if (initializing === true) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" />
      </div>
    );
  }

  const browserName = browser.getBrowserName();
  if (browserName === 'Internet Explorer') {
    return (
      <ErrorBrowser />
    )
  }

  return (
    <userContext.Provider value={{ user }}>
      <Pages />
    </userContext.Provider>
  );
}

export default App;
