import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools"
import { updateEventPageHTMLs } from '../../database/events';
import ErrorPage from '../ErrorPage';

function PageHTMLs(props) {
  const { event } = props;
  const eventKey = event && event.data && event.data.eventKey
    ? event.data.eventKey
    : null;

  // States
  const pageHTMLsInit = event && event.data && event.data.pageHTMLs
    ? event.data.pageHTMLs
    : {
      liveSoon: '',
      liveNow: '',
      liveDone: '',
    };
  const [pageHTMLs, setPageHTMLs] = useState(pageHTMLsInit);

  if (!eventKey) {
    return (
      <ErrorPage />
    )
  }

  
  return (
    <div className="bg-white shadow rounded p-5 mt-5">
      <div className="mb-10">
        <h3 className="font-bold">Live Soon</h3>
        <div className="flex">
          <div className="w-1/2 mr-5">
            <AceEditor
              placeholder=""
              mode="html"
              theme="monokai"
              name="liveSoon"
              onChange={(newValue) => {
                setPageHTMLs({
                  ...pageHTMLs,
                  liveSoon: newValue,
                })
              }}
              fontSize={14}
              wrapEnabled={true}
              maxLines={Infinity}
              width={'100%'}
              showGutter={true}
              showPrintMargin={false}
              highlightActiveLine={true}
              value={pageHTMLs['liveSoon']}
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
          </div>
          <div className="w-1/2 p-5 border-2 rounded">
            <div dangerouslySetInnerHTML={{__html: pageHTMLs['liveSoon']}} />
          </div>
        </div>
      </div>

      <div className="flex items-center mt-5">
        <button
          className="mx-auto bg-gray-700 text-white py-2 px-4 rounded focus:outline-none"
          onClick={() => {
            updateEventPageHTMLs({ eventKey, pageHTMLs });
          }}
        >
          Update page HTMLs
        </button>
      </div>

      <div className="my-10">
        <h3 className="font-bold">Live Now</h3>
        <div className="flex">
          <div className="w-1/2 mr-5">
            <div>
              <AceEditor
                placeholder=""
                mode="html"
                theme="monokai"
                name="liveNow"
                onChange={(newValue) => {
                  setPageHTMLs({
                    ...pageHTMLs,
                    liveNow: newValue,
                  })
                }}
                fontSize={14}
                wrapEnabled={true}
                maxLines={Infinity}
                width={'100%'}
                showGutter={true}
                showPrintMargin={false}
                highlightActiveLine={true}
                value={pageHTMLs['liveNow']}
                setOptions={{
                  enableBasicAutocompletion: false,
                  enableLiveAutocompletion: false,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
              />
            </div>
          </div>
          <div className="w-1/2 p-5 border-2 rounded">
            <div dangerouslySetInnerHTML={{__html: pageHTMLs['liveNow']}} />
          </div>
        </div>
      </div>

      <div className="flex items-center mt-5">
        <button
          className="mx-auto bg-gray-700 text-white py-2 px-4 rounded focus:outline-none"
          onClick={() => {
            updateEventPageHTMLs({ eventKey, pageHTMLs });
          }}
        >
          Update page HTMLs
        </button>
      </div>

      <div className="my-10">
        <h3 className="font-bold">Live Done</h3>
        <div className="flex">
          <div className="w-1/2 mr-5">
            <div>
              <AceEditor
                placeholder=""
                mode="html"
                theme="monokai"
                name="liveDone"
                onChange={(newValue) => {
                  setPageHTMLs({
                    ...pageHTMLs,
                    liveDone: newValue,
                  })
                }}
                fontSize={14}
                wrapEnabled={true}
                maxLines={Infinity}
                width={'100%'}
                showGutter={true}
                showPrintMargin={false}
                highlightActiveLine={true}
                value={pageHTMLs['liveDone']}
                setOptions={{
                  enableBasicAutocompletion: false,
                  enableLiveAutocompletion: false,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
              />
            </div>
          </div>
          <div className="w-1/2 p-5 border-2 rounded">
            <div dangerouslySetInnerHTML={{__html: pageHTMLs['liveDone']}} />
          </div>
        </div>
      </div>


      <div className="flex items-center mt-5">
        <button
          className="mx-auto bg-gray-700 text-white py-2 px-4 rounded focus:outline-none"
          onClick={() => {
            updateEventPageHTMLs({ eventKey, pageHTMLs });
          }}
        >
          Update page HTMLs
        </button>
      </div>

    </div>
  );
}

PageHTMLs.propTypes = {
  event: PropTypes.object.isRequired,
};

export default PageHTMLs;
