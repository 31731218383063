import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import { useEvent } from '../../database/events';
import { updateEventData } from '../../database/events';
import { Link } from 'react-router-dom';
import PageHTMLs from './PageHTMLs';
import Attendees from './Attendees';
import QuickControl from './QuickControl';
import Email from './Email';


function EventSettings(props) {
  const { eventKey } = props;
  const event = useEvent({ eventKey });

  const [adminPage, setAdminPage] = React.useState('attendees');
  const [loadingName, setLoadingName] = React.useState();
  const [loadingDate, setLoadingDate] = React.useState();

  const language = event && event.data && event.data.language
    ? event.data.language
    : 'NL';
  const openRegistration = event && event.data && event.data.openRegistration
    ? event.data.openRegistration
    : true;
  const focusPlanRender = event && event.data && event.data.focusPlanRender
    ? event.data.focusPlanRender
    : true;
  const mediaPlayerType = event && event.data && event.data.mediaPlayerType
    ? event.data.mediaPlayerType
    : 'youtube';

  const helpInstructions = event && event.data && event.data.helpInstructions
    ? event.data.helpInstructions
    : 'Voor technische ondersteuning mail naar: <a className="font-bold hover:underline" href="mailto:sandra@focusacademy.com">sandra@focusacademy.com</a>';


  if (!event || event.loading) {
    return (
      <div>
        Loading..
      </div>
    )
  }

  if (!event.data) {
    return (
      <div>
        Event not found
      </div>
    )
  }

  const { name, startDateTime, pageStatus } = event.data;
  const startDateTimePicker = !!startDateTime
    ? new Date(startDateTime)
    : new Date()

  return (
    <div>
      <div className="p-10 mx-auto">

        <div className="mb-5">
          <Link
            className="inline-block bg-gray-300 hover:bg-gray-400 py-1 px-2 text-sm rounded focus:outline-none"
            to="/admin"
          >
          <i class="fas fa-arrow-left mr-2"></i> All events
          </Link>
        </div>

        <div className="shadow overflow-hidden border-b border-gray-200 rounded max-w-6xl text-sm text-left mb-5">
          <div className="table-auto bg-white shadow rounded w-full divide-y divide-gray-200">
            <div className="flex">
              <div className="p-2 w-2/6 font-normal">Working title</div>
              <div className="p-2 flex-grow">
                <button
                  type="button"
                  className="text-left w-full hover:underline text-sm font-bold focus:outline-none"
                  onClick={() => {
                    const newName = window.prompt('Change title', name);
                    if (!newName || typeof newName !== 'string') {
                      return;
                    }

                    // Change name in the database
                    setLoadingName(true);
                    updateEventData({ eventKey, data: { name: newName } })
                      .then(() => {
                        setLoadingName(false);
                      });
                  }}
                >
                  {
                    !!loadingName
                    && <i className="fas fa-cog fa-spin"></i>
                  }
                  {
                    !loadingName
                    && name
                  }
                </button>
              </div>
            </div>
            <div className="flex">
              <div className="p-2 w-2/6 font-normal">Date</div>
              <div className="p-2 flex-grow">
                {
                  !!loadingDate
                  && (
                    <div className="w-48 mr-auto rounded py-1 px-2 text-sm focus:outline-none">
                      <i className="fas fa-cog fa-spin"></i>
                    </div>
                  )
                }
                {
                  !loadingDate
                  && (
                    <DatePicker
                      selected={startDateTimePicker}
                      locale="nl"
                      showTimeSelect
                      dateFormat="dd MMMM yyyy, HH:mm"
                      className="w-48 cursor-pointer hover:underline rounded p-0 text-sm focus:outline-none"
                      onChange={(date) => {
                        setLoadingDate(true);
                        updateEventData({ eventKey, data: { startDateTime: date.valueOf() } })
                          .then(() => {
                            setLoadingDate(false);
                          });
                      }}
                    />
                  )
                }
              </div>
            </div>
            <div className="flex">
              <div className="p-2 w-2/6 font-normal">Language</div>
              <div className="p-2 flex-grow">
                <select
                  className="block p-1 bg-gray-700 text-white py-1 px-2 rounded focus:outline-none"
                  value={language}
                  onChange={(event) => {
                    updateEventData({ eventKey, data: { language: event.target.value  } });
                  }}
                >
                  <option value="NL">NL</option>
                  <option value="EN">EN</option>
                </select>
              </div>
            </div>
            <div className="flex">
              <div className="p-2 w-2/6 font-normal">Open Registration</div>
              <div className="p-2 flex-grow">
                <select
                  className="block p-1 bg-gray-700 text-white py-1 px-2 rounded focus:outline-none"
                  value={openRegistration}
                  onChange={(event) => {
                    updateEventData({ eventKey, data: { openRegistration: event.target.value } });
                  }}
                >
                  <option value="off">Off</option>
                  <option value="on">On</option>
                  <option value="anonymous">Anonymous</option>
                </select>
              </div>
            </div>
            <div className="flex">
              <div className="p-2 w-2/6 font-normal">Focus Plan</div>
              <div className="p-2 flex-grow">
                <select
                  className="block p-1 bg-gray-700 text-white py-1 px-2 rounded focus:outline-none"
                  value={focusPlanRender}
                  onChange={(event) => {
                    updateEventData({ eventKey, data: { focusPlanRender: event.target.value } });
                  }}
                >
                  <option value="on">On</option>
                  <option value="off">Off</option>
                </select>
              </div>
            </div>
            <div className="flex">
              <div className="p-2 w-2/6 font-normal">Media Player</div>
              <div className="p-2 flex-grow">
                <select
                  className="block p-1 bg-gray-700 text-white py-1 px-2 rounded focus:outline-none"
                  value={mediaPlayerType}
                  onChange={(event) => {
                    updateEventData({ eventKey, data: { mediaPlayerType: event.target.value } });
                  }}
                >
                  <option value="youtube">Youtube</option>
                  <option value="amazon">Amazon</option>
                </select>
              </div>
            </div>
            <div className="flex">
              <div className="p-2 w-2/6 font-normal flex-shrink-0">Help instructions</div>
              <div className="p-2 flex-grow">
                <button
                  type="button"
                  className="text-left w-full hover:underline text-sm font-bold focus:outline-none"
                  onClick={() => {
                    const newHelpInstructions = window.prompt('Change title', helpInstructions);
                    if (!newHelpInstructions || typeof newHelpInstructions !== 'string') {
                      return;
                    }
                    updateEventData({ eventKey, data: { helpInstructions: newHelpInstructions } })
                  }}
                >
                  {
                    helpInstructions
                  }
                </button>
              </div>
            </div>
            <div className="flex">
              <div className="p-2 w-2/6 font-normal">Url</div>
              <div className="p-2 flex-grow whitespace-no-wrap select-all">
                http://focusacademy.live/e/{eventKey}
              </div>
            </div>
            <div className="flex">
              <div className="p-2 w-2/6 font-normal">Event status</div>
              <div className="p-2 flex-grow">
                {
                  (
                    !pageStatus
                    || pageStatus === 'liveSoon'
                  )
                  && (
                    <span className="inline-block text-sm py-1 px-2 rounded bg-green-400 text-green-800">
                      event soon
                    </span>
                  )
                }
                {
                  !!pageStatus
                  && pageStatus === 'liveNow'
                  && (
                    <span className="inline-block text-sm py-1 px-2 rounded bg-red-400 text-red-800">
                      event live
                    </span>
                  )
                }
                {
                  !!pageStatus
                  && pageStatus === 'liveDone'
                  && (
                    <span className="inline-block text-sm py-1 px-2 rounded bg-gray-200 text-gray-600">
                      event done
                    </span>
                  )
                }
              </div>
            </div>
            <div className="flex">
              <div className="p-2 w-2/6 font-normal">Live page link</div>
              <div className="p-2 flex-grow">
                <Link
                  className="inline-block bg-gray-700 text-white py-1 px-2 text-sm rounded focus:outline-none"
                  to={`/e/${eventKey}/u/admin`}
                >
                  live page <i className="fas fa-angle-right ml-1"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <QuickControl event={event} />

        <div className="flex mt-10">
          <button
            className={
              adminPage === 'attendees'
                ? 'inline-block mr-2 bg-gray-700 text-gray-100 py-1 px-2 text-sm rounded cursor-default focus:outline-none'
                : 'inline-block mr-2 bg-gray-300 hover:bg-gray-400 py-1 px-2 text-sm rounded focus:outline-none'
            }
            disabled={adminPage === 'attendees'}
            onClick={() => {
              setAdminPage('attendees')
            }}
          >
            Attendees
          </button>
          <button
            className={
              adminPage === 'html'
                ? 'inline-block mr-2 bg-gray-700 text-gray-100 py-1 px-2 text-sm rounded cursor-default focus:outline-none'
                : 'inline-block mr-2 bg-gray-300 hover:bg-gray-400 py-1 px-2 text-sm rounded focus:outline-none'
            }
            disabled={adminPage === 'html'}
            onClick={() => {
              setAdminPage('html')
            }}
          >
            HTML pages
          </button>
          <button
            className={
              adminPage === 'email'
                ? 'inline-block bg-gray-700 text-gray-100 py-1 px-2 text-sm rounded cursor-default focus:outline-none'
                : 'inline-block bg-gray-300 hover:bg-gray-400 py-1 px-2 text-sm rounded focus:outline-none'
            }
            disabled={adminPage === 'email'}
            onClick={() => {
              setAdminPage('email')
            }}
          >
            Email
          </button>
        </div>

        <div>

          <div>
            {
              adminPage === 'html'
              && <PageHTMLs event={event} />
            }
            {
              adminPage === 'attendees'
              && <Attendees event={event} />
            }
            {
              adminPage === 'email'
              && <Email event={event} />
            }
          </div>
        </div>

      </div>

    </div>
  )
}

EventSettings.propTypes = {
  eventKey: PropTypes.string.isRequired,
};

export default EventSettings;
