import React from 'react';
import { login, logout, useUser } from '../../database/auth';

function Header() {
  const user = useUser();

  return (
    <div className="flex p-3 px-8 text-gray-400 bg-gray-800 items-center justify-between">
      <div>
        <div className="text-gray-200">
          <span className="font-bold">Focus Academy</span> <span className="text-red-500">Live</span>
        </div>
      </div>
      {
        // Logged in
        !!user
        && !!user.displayName
        && (
          <div className="flex items-center">
            <div>{ user.displayName }</div>
            <button
              type="button"
              className="ml-2 bg-gray-700 text-white py-1 px-2 text-sm rounded focus:outline-none"
              onClick={() => {
                logout();
              }}
            >
              Logout
            </button>
          </div>
        )
      }
      {
        // Not logged in
        !user
        && (
          <div className="flex items-center">
            <button
              type="button"
              className="ml-2 bg-indigo-700 text-white py-1 px-2 text-sm rounded focus:outline-none"
              onClick={() => {
                login();
              }}
            >
              Login
            </button>
          </div>
        )
      }
    </div>
  );
}

export default Header;
