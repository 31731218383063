import React from 'react';
import PropTypes from 'prop-types';
import InputBar from './inputBar';
import Messages from './Messages';
import { updateEventAttendeeMinChat } from '../../../database/events';


function Chat(props) {
  const { event, attendee, isAdmin } = props;
  const { eventKey } = event.data;

  const attendeeKey = attendee && attendee.data && attendee.data.attendeeKey
    ? attendee.data.attendeeKey
    : null;

  const attendeeMinChat = attendee && attendee.data && attendee.data.minChat
    ? attendee.data.minChat
    : null;

  const chatStatus = event && event.data && event.data.chatStatus
    ? event.data.chatStatus
    : 'off';

  if (attendeeMinChat) {
    return (
      <div className="flex-grow bg-gray-800 relative">
        <div className="w-full h-full flex flex-col">
          <div className="flex justify-end p-2">
            <button
              className="inline-block bg-gray-700 hover:bg-gray-600 text-gray-900 rounded px-1 text-sm leading-normal focus:outline-none"
              onClick={() => {
                if (!eventKey || !attendeeKey) {
                  return;
                }
                updateEventAttendeeMinChat({ eventKey, attendeeKey, minChat: false })
              }}
            >
              <i className="far fa-eye"></i>
            </button>
          </div>
          <div
            className="flex-grow bg-gray-300"
            style={{ WebkitFlexGrow: '1' }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex-grow bg-gray-800 relative">
      <div className="absolute top-0 left-0 w-full h-full flex flex-col">
        <div className="absolute top-0 right-0 flex justify-end p-2">
          <button
            className="inline-block bg-gray-700 hover:bg-gray-600 text-gray-900 rounded px-1 text-sm leading-normal focus:outline-none"
            onClick={() => {
              if (!eventKey || !attendeeKey) {
                return;
              }
              updateEventAttendeeMinChat({ eventKey, attendeeKey, minChat: true })
            }}
          >
            <i className="far fa-eye-slash"></i>
          </button>
        </div>
        <div
          className="flex-grow"
          style={{ WebkitFlexGrow: '1' }}
        />

        {
          !!chatStatus
          && chatStatus === 'off'
          && (
            <div className="h-full flex items-center justify-center">
              <div className="text-center text-lg text-gray-700">
                <div>Focus AAN, chat UIT.</div>
              </div>
            </div>
          )
        }

        {
          !!chatStatus
          && chatStatus !== 'off'
          && (
            <>
              <Messages event={event} attendee={attendee} isAdmin={isAdmin} />
              <InputBar event={event} attendee={attendee} isAdmin={isAdmin} />
            </>
          )
        }
        
      </div>
    </div>
  );
}


Chat.defaultProps = {
  attendee: null,
}

Chat.propTypes = {
  event: PropTypes.object.isRequired,
  attendee: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
};

export default Chat;
