import React from 'react';
import { useParams } from "react-router-dom";
import { logout, useUser } from '../../database/auth';
import EventSettings from './EventSettings';
import Header from './Header';
import ListEvents from './ListEvents';
import ListFocusPlanMails from './ListFocusPlanMails';

function Admin() {
  const { eventKey } = useParams();
  const user = useUser();

  // Check if email domain is focusacademy.com
  if (user && !/@focusacademy.com\s*$/.test(user.email)) {
    logout();
  }

  if (!user) {
    return (
      <div>
        <Header />
        <div className="p-5">
          <div className="max-w-lg mx-auto p-5 m-10 bg-gray-300 rounded-lg text-sm">
            <i class="fas fa-user-shield mr-2"></i> Please login with a Focus Academy email account.
          </div>
        </div>
      </div>
    )
  }

  // Event page if /admin/e/:eventKey
  if (eventKey) {
    return (
      <div>
        <Header />
        <div className="p-5">
          <EventSettings eventKey={eventKey} />
        </div>
      </div>
    );
  }

  // Overview page if /admin (no eventKey)
  return (
    <div>
      <Header />
      <div className="p-5">
        <ListEvents />
        <ListFocusPlanMails />
      </div>
    </div>
  );
}

export default Admin;
