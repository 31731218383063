import React from 'react';
import { useEvents } from '../../database/events';
import ListEvent from './ListEvent';
import CreateEvent from './CreateEvent';

function ListEvents() {
  const events = useEvents();

  // Events in the future
  const futureEvents = events && events.data && events.data.length
    ? events.data.filter((event) => {
      const { startDateTime } = event;
      if (startDateTime < new Date().valueOf() ) {
        return false;
      }
      return true;
    })
    : null;
  if (futureEvents && futureEvents.length) {
    futureEvents.reverse();
  }
  
  // Events in the past
  const pastEvents = events && events.data && events.data.length
    ? events.data.filter((event) => {
      const { startDateTime } = event;
      if (startDateTime < new Date().valueOf() ) {
        return true;
      }
      return false;
    })
    : null;

  return (
    <div className="max-w-6xl mx-auto mt-10">

      <CreateEvent />
      
      <div className="mb-10">
        <h2 className="mb-2 font-bold">Future events</h2>
        <div className="shadow overflow-hidden border-b border-gray-200 rounded w-full">
          <table className="table-auto bg-white shadow rounded w-full divide-y divide-gray-200">
            <thead className="bg-gray-100 rounded-t text-sm text-left">
              <tr>
                <th className="p-2 font-medium">Title</th>
                <th className="p-2 font-medium">Date</th>
                <th className="p-2 font-medium">Event status</th>
                <th className="p-2 font-medium">Admin page</th>
                <th className="p-2 font-medium">Live page</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {
                !!futureEvents
                && !!futureEvents.length
                && futureEvents.map(event => {
                  const { key } = event;
                  return (
                    <ListEvent key={key} event={event} />
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
      
      <div className="mb-10">
        <h2 className="mb-2 font-bold">Past events</h2>
        <table className="table-auto bg-white shadow rounded w-full divide-y divide-gray-200">
          <thead className="bg-gray-100 rounded-t text-sm text-left">
            <tr>
              <th className="p-2 font-medium">Title</th>
              <th className="p-2 font-medium">Date</th>
              <th className="p-2 font-medium">Language</th>
              <th className="p-2 font-medium">Event status</th>
              <th className="p-2 font-medium">Admin page</th>
              <th className="p-2 font-medium">Live page</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {
              !!pastEvents
              && !!pastEvents.length
              && pastEvents.map(event => {
                const { key } = event;
                return (
                  <ListEvent key={key} event={event} />
                )
              })
            }
          </tbody>
        </table>
      </div>

    </div>
  );
}

export default ListEvents;
