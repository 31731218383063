import React from 'react';
import { updateEventAttendeeFocusPlan } from '../../../database/events';


function Item(props) {
  const { event, attendeeKey, itemKey, item, cl, category, focusPlan } = props;
  const { eventKey } = event.data;

  return (
    <div
      key={itemKey}
      className="group hover:bg-gray-400 rounded p-1 px-2 flex"
    >
      <div className="flex-grow flex items-center">
        <i className="fas fa-circle mr-2 text-xs text-gray-500"></i>
        { item }
      </div>
      <div className="hidden group-hover:block">
        <button
          className="inline-block bg-gray-500 hover:bg-gray-600 text-gray-900 rounded px-1 text-sm leading-normal focus:outline-none"
          onClick={() => {
            const newFocusPlan = {
              ...focusPlan,
            }
            newFocusPlan[cl][category].splice(itemKey, 1);
            
            updateEventAttendeeFocusPlan({ eventKey, attendeeKey, focusPlan: newFocusPlan });
          }}
        >
          <i className="far fa-trash-alt"></i>
        </button>
      </div>
    </div>
  );
}

export default Item;
