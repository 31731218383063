import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { useEventMessagesMostLiked } from '../../database/events';
import Message from './Chat/Message';


function MostPopularMessages(props) {
  const { event, attendee, isAdmin } = props;
  const { eventKey } = event.data;
  const messages = useEventMessagesMostLiked({ eventKey });

  const sortedMessages = messages && messages.data && messages.data.length
    ? _.sortBy(messages.data, function(o) { return o.totalLikes })
    : null;

  let count = 0;

  return (
    <div>
      {
        !!messages
        && !!messages.loading
        && (
          <div className="text-gray-500 text-center">
            <i className="fas fa-cog fa-spin" />
          </div>
        )
      }

      {
        !!sortedMessages
        && !!sortedMessages.length
        && (
          sortedMessages.reverse().map((messageData, index) => {
            if (!messageData.totalLikes) {
              return null;
            }

            if (messageData.totalLikes <= 1) {
              return null;
            }
          
            if (messageData.hidden) {
              return null;
            }

            
            if (count >= 10) {
              return null;
            }

            count++;
            return <Message key={messageData.messageKey} event={event} attendee={attendee} isAdmin={isAdmin} messageData={messageData} />
          })
        )
      }
    </div>
  );
}


MostPopularMessages.defaultProps = {
  attendee: null,
}

MostPopularMessages.propTypes = {
  event: PropTypes.object.isRequired,
  attendee: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
};

export default MostPopularMessages;
