import React, { useState } from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {
  useEventAttendees,
  addEventAttendees,
  deleteEventAttendee,
} from '../../database/events';
import ErrorPage from '../ErrorPage';

function Attendees(props) {
  const { event } = props;
  const eventKey = event && event.data && event.data.eventKey
    ? event.data.eventKey
    : null;

  // Attendees
  const attendees = useEventAttendees({ eventKey });
  const [newAttendees, setNewAttendees] = useState();


  if (!eventKey) {
    return (
      <ErrorPage />
    )
  }


  // calculate all fingerprints
  const allFingerprints = attendees && attendees.data && attendees.data.length
    ? _.chain(attendees.data)
      .pluck('fingerprints')
      .flatten()
      .filter((value) => {
        return !!value;
      })
      .countBy('visitorId')
      .value()
    : null;

  
  return (
    <div className="bg-white shadow rounded p-5 mt-5">
      <h3 className="font-bold mb-2">
        Attendees
        {
          !!attendees
          && !!attendees.data
          && !!attendees.data.length
          && (
            <span className="ml-2">({ attendees.data.length })</span>
          )
        }
      </h3>

      <div>

        <table className="table-auto bg-white shadow rounded w-full divide-y divide-gray-200">
          <thead className="bg-gray-100 rounded-t text-sm text-left">
            <tr>
              <th className="p-2 font-medium">Name</th>
              <th className="p-2 font-medium">Email</th>
              <th className="p-2 font-medium">Link</th>
              <th className="p-2 font-medium">
                Fingerprints (
                  {
                    !!allFingerprints && Object.keys(allFingerprints).length
                  }
                )
              </th>
              <th className="p-2 font-medium">Added</th>
              <th className="p-2 font-medium">Delete</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {
              !!attendees
              && !!attendees.data
              && !!attendees.data.length
              && attendees.data.map(attendee => {
                const { name, email, attendeeKey, fingerprints, created } = attendee;
                
                const fingerprintsCount = _.chain(fingerprints)
                  .countBy('visitorId')
                  .value();

                return (
                  <tr>
                    <td className="p-2 font-bold">
                      { name }
                    </td>
                    <td className="p-2">
                      { email }
                    </td>
                    <td className="p-2 text-xs font-mono select-all">
                      {`http://focusacademy.live/e/${eventKey}/u/${attendeeKey}`}
                    </td>
                    <td className="p-2">
                      <pre className="text-xs">
                        {
                          !!fingerprintsCount
                          && !!Object.keys(fingerprintsCount)
                          && !!Object.keys(fingerprintsCount).length
                          && Object.keys(fingerprintsCount).map((visitorId) => {
                            return (
                              <div>
                                {visitorId}: {fingerprintsCount[visitorId]}
                              </div>
                            )
                          })
                          
                        }
                      </pre>
                    </td>
                    <td className="p-2">
                      {
                        !!created
                        && (
                          moment(created.toDate()).format('D-MM-YYYY HH:mm')
                        )
                      }
                    </td>
                    <td className="p-2">
                      <button
                        type="button"
                        className="bg-red-700 text-white text-xs py-1 px-2 rounded focus:outline-none"
                        onClick={() => {
                          const sure = window.confirm('Are you sure?');
                          if (!sure) {
                            return;
                          }
                          deleteEventAttendee({ eventKey, attendeeKey });
                        }}
                      >
                        delete
                      </button>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>

      </div>

      <div className="flex my-5">
        <button
          type="button"
          className="mx-auto bg-gray-700 text-white py-2 px-4 rounded focus:outline-none"
          onClick={() => {
            if (
              !attendees
              || !attendees.data
              || !attendees.data.length
            ) {
              return;
            }
            const copyText = attendees.data.map((attendee) => {
              const { name, email, attendeeKey, created } = attendee;
              return `${name}	${email}	https://focusacademy.live/e/${eventKey}/u/${attendeeKey}	${!!created && moment(created.toDate()).format('D-MM-YYYY HH:mm')}`;
            }).join('\r\n');
            navigator.clipboard.writeText(copyText);
            window.alert(copyText);
          }}
        >
          Copy attendees to clipboard
        </button>
      </div>


      <pre className="bg-white shadow p-5 mt-10 mb-5 rounded whitespace-pre-wrap">{JSON.stringify(newAttendees, null, 2) }</pre>
      

      <div className="flex">
        <button
          type="button"
          className="bg-gray-700 text-white py-2 px-4 rounded focus:outline-none"
          onClick={() => {
            navigator.clipboard.readText()
              .then(text => {
                const newAttendeesRaw = text.split('\n');
                if (!newAttendeesRaw || !newAttendeesRaw.length) {
                  console.log('Error, paste is not an array.')
                  return;
                }
                const newAttendeesGenerated = newAttendeesRaw.map(newAttendeeRaw => {
                  const newAttendeeData = newAttendeeRaw.split('	');
                  if (!newAttendeeData || !newAttendeeData[0] || !newAttendeeData[1]) {
                    console.log('Data of attendee not enough.')
                    return null;
                  }
                  return {
                    name: newAttendeeData[0],
                    email: newAttendeeData[1],
                    attendeeKey: uuidv4(),
                  }
                });

                setNewAttendees(newAttendeesGenerated);
              })
              .catch(err => {
                console.error('Failed to read clipboard contents: ', err);
              });
          }}
        >
          Paste new attendees
        </button>
        <button
          type="button"
          className="ml-2 bg-gray-700 text-white py-2 px-4 rounded focus:outline-none"
          onClick={() => {

            const amountOfEmptyAttendees = parseInt(window.prompt("How many empty attendees?", "0"), 10);
            if (!amountOfEmptyAttendees) {
              return;
            }
            const newAttendeesGenerated = Array.from(Array(amountOfEmptyAttendees).keys()).map(() => {
              return {
                attendeeKey: uuidv4(),
              }
            });
            setNewAttendees(newAttendeesGenerated);
          }}
        >
          Create empty attendees
        </button>
        <button
          type="button"
          className={
            newAttendees
              ? 'ml-auto bg-gray-700 text-white py-2 px-4 rounded focus:outline-none'
              : 'ml-auto bg-gray-300 text-gray-200 py-2 px-4 rounded focus:outline-none pointer-events-none'
          }
          onClick={() => {
            addEventAttendees({ eventKey, newAttendees });
            setNewAttendees();
          }}
        >
          Add new Attendees
        </button>
      </div>
    </div>
  );
}

Attendees.propTypes = {
  event: PropTypes.object.isRequired,
};

export default Attendees;
