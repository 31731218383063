import React, { useEffect, useState } from 'react';
import { updateEventAttendeeFocusPlan } from '../../../database/events';


function InputBar(props) {
  const { event, attendeeKey, cl, category, focusPlan } = props;
  const { eventKey, language } = event.data;

  const [input, setInput] = useState('');


  useEffect(() => {
    const upHandler = (event) => {
      if (event.keyCode !== 13 || !input || !input.length) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();

      const newFocusPlan = {
        ...focusPlan,
      }
      newFocusPlan[cl][category].push(input);
      updateEventAttendeeFocusPlan({ eventKey, attendeeKey, focusPlan: newFocusPlan });
      setInput('');
    }
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keyup', upHandler);
    };
  }, [input, attendeeKey, category, cl, eventKey, focusPlan]);


  return (
    <div className="border-t bg-gray-400 rounded-b border-gray-400 flex">
      <div className="flex-grow rounded-bl bg-gray-300">
        <input
          type="text"
          value={input}
          onChange={e => setInput(e.target.value)}
          className="focus:outline-none bg-gray-300 h-10 p-2 px-3 text-sm rounded-bl text-gray-700 block w-full leading-normal appearance-none"
          placeholder={
            language === 'EN'
              ? 'Add item..'
              : 'Voeg item toe..'
          }
        />
      </div>
      <div className="flex items-center rounded">
        <button
          type="button"
          className={`
            w-10 h-10 bg-gray-300 hover:bg-gray-400 flex rounded-br justify-center items-center content-center focus:outline-none
            ${!input && 'bg-gray-200'}
          `}
          onClick={() => {
            if (!input || !input.length) {
              return;
            }
            const newFocusPlan = {
              ...focusPlan,
            }
            newFocusPlan[cl][category].push(input);
            updateEventAttendeeFocusPlan({ eventKey, attendeeKey, focusPlan: newFocusPlan });
            setInput('');
          }}
        >
          <i className="fas fa-plus text-gray-600"></i>
        </button>
      </div>
    </div>
  );
}


export default InputBar;
