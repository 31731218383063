import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';


export const config = {
  apiKey: 'AIzaSyCSU3nVV3GBPsn7lqwX3nUWKw8CCc7zVdA',
  authDomain: 'focusacademy-live.firebaseapp.com',
  databaseURL: 'https://focusacademy-live.firebaseio.com',
  projectId: 'focusacademy-live',
  storageBucket: 'focusacademy-live.appspot.com',
  messagingSenderId: '618499116357',
  appId: '1:618499116357:web:215f3da01a35874bef422c',
  measurementId: 'G-327DHZDPBV',
};

firebase.initializeApp(config);
export const functions = firebase.functions();
export const db = firebase.firestore();
