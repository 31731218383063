import React from 'react';
import { useFocusPlanMails } from '../../database/events';

function ListFocusPlanMails() {
  const { data: mails } = useFocusPlanMails();
  console.log(mails);
  if (!mails) {
    return null;
  }
  

  return (
    <div className="max-w-6xl mx-auto mt-10">

      <div className="mb-10">
        <h2 className="mb-2 font-bold">Focus Plan Mails {`(${mails.length})`}</h2>
        <table className="table-auto bg-white shadow rounded w-full divide-y divide-gray-200">
          <thead className="bg-gray-100 rounded-t text-sm text-left">
            <tr>
              <th className="p-2 font-medium hidden">Key</th>
              <th className="p-2 font-medium">Email</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {
              !!mails
              && !!mails.length
              && mails.map(event => {
                const { key, email } = event;
                return (
                  <tr>
                    <td className="hidden p-1">{ key }</td>
                    <td className="p-1">{ email }</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

    </div>
  );
}

export default ListFocusPlanMails;
