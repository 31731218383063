import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useEventMessages } from '../../../database/events';
import Message from './Message';


function Messages(props) {
  const { event, isAdmin, attendee } = props;
  const { eventKey } = event.data;
  const messages = useEventMessages({ eventKey });


  // Scroll vars
  const [manualScroll, setManualScroll] = React.useState(false);
  const scrollRef = React.useRef(null);
  const messagesEndRef = React.useRef(null);

  // Scroll to bototm
  const scrollToBottom = () => {
    if(!messagesEndRef || !messagesEndRef.current || manualScroll) {
      return;
    }

    scrollRef.current.scrollTop = messagesEndRef.current.offsetTop;
  }

  // Manual scroll?
  const scrolled = () => {
    const scrollYCeil = Math.ceil(scrollRef.current.scrollTop);
    const scrollYFloor = Math.floor(scrollRef.current.scrollTop);
    const maxY = Math.ceil(scrollRef.current.scrollHeight - scrollRef.current.clientHeight);

    if (maxY === scrollYCeil || maxY === scrollYFloor) {
      setManualScroll(false);
      return;
    }
    setManualScroll(true);
  }

  // Scroll to bottom - on change messages (or ref)
  useEffect(
    scrollToBottom,
    [messagesEndRef.current, messages]
  );

  // Scroll to bottom - on resize
  useEffect(() => {
    const scrollToBottom = () => {
      if(!messagesEndRef || !messagesEndRef.current || manualScroll) {
        return;
      }
  
      scrollRef.current.scrollTop = messagesEndRef.current.offsetTop;
    }

    window.addEventListener('resize', scrollToBottom);
    return () => {
      window.removeEventListener('resize', scrollToBottom);
    };
  }, [manualScroll]);


  return (
    <div
      ref={scrollRef}
      onScroll={scrolled}
      className="overflow-auto scrolling-touch p-3 py-5 scrollbar"
      style={{ maxHeight: '100%'}}
    >

      {
        !!messages
        && !!messages.loading
        && (
          <div className="text-gray-500 text-center">
            <i className="fas fa-cog fa-spin" />
          </div>
        )
      }

      {
        !!messages
        && !!messages.data
        && !!messages.data.length
        && (
          messages.data.map((messageData) => {
            return <Message key={messageData.messageKey} event={event} attendee={attendee} isAdmin={isAdmin} messageData={messageData} />
          })
        )
      }

      <div ref={messagesEndRef} />
    </div>
  );
}


Messages.defaultProps = {
  attendee: null,
}

Messages.propTypes = {
  event: PropTypes.object.isRequired,
  attendee: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
};

export default Messages;
