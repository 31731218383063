import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createAnonymousOpenRegistration } from '../../database/events';
import MediaPlayer from './MediaPlayer';
import AmazonPlayer from './AmazonPlayer';


function RegistrationFormAnonymous(props) {
  const { event } = props;

  const eventKey = event && event.data && event.data.eventKey ? event.data.eventKey : null;
  const eventTitle = event && event.data && event.data.name ? event.data.name : null;
  const eventLanguage = event && event.data && event.data.language ? event.data.language : null;
  const pageHTML = event && event.data && event.data.pageHTMLs && event.data.pageHTMLs['liveSoon']
    ? event.data.pageHTMLs['liveSoon']
    : null;
  const language = event && event.data && event.data.language
    ? event.data.language
    : 'NL';

  const mediaPlayerType = event && event.data && event.data.mediaPlayerType
    ? event.data.mediaPlayerType
    : 'youtube';

  const [name, setName] = useState('');
  const [registering, setRegistering] = useState();

  return (
    <div className="p-5">
      <div className="flex-grow mx-auto lg:max-w-2xl mt-20">

        <div className="mb-2 font-bold text-lg text-gray-700">
          {
            language !== 'EN'
            && 'Schrijf je in voor het live online evenement'
          }
          {
            language === 'EN'
            && 'Sign up for the live online event'
          }
        </div>

        <form
          className="shadow overflow-hidden sm:rounded-md"
          onSubmit={(event) => {
            event.preventDefault();
            if (!name || !eventKey || !eventTitle || !eventLanguage) {
              return null;
            }
            setRegistering('loading');
            createAnonymousOpenRegistration({ name, eventKey, eventTitle, eventLanguage })
              .then((result) => {
                setRegistering('success')
                if (!result || !result.data || !result.data.newAttendee || !result.data.newAttendee.attendeeKey) {
                  setRegistering('error');
                  return;
                }

                // redirect to session
                window.location.href = `/e/${eventKey}/u/${result.data.newAttendee.attendeeKey}`;
              }).catch((error) => {
                console.log({error});
                setRegistering('error');
              });

          }}
        >
          <div className="px-4 py-5 bg-white">
            <div className="">
              <div className="">
                <label className="block text-sm font-medium leading-5 text-gray-700">
                  {
                    language !== 'EN'
                    && 'Naam'
                  }
                  {
                    language === 'EN'
                    && 'Name'
                  }
                </label>
                <input
                  id="first_name"
                  className="mt-1 bg-white border rounded p-2 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                  required
                />
              </div>

            </div>
          </div>
          <div className="px-4 py-3 bg-gray-700 text-right sm:px-6">
            {
              !!registering
              && registering === 'loading'
              && (
                <i className="text-white fas fa-cog fa-spin" />
              )
            }
            {
              !registering
              && (
                <button
                  type="submit"
                  className="py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 shadow-sm hover:bg-green-500 focus:outline-none focus:shadow-outline-blue active:bg-indigo-600 transition duration-150 ease-in-out"
                >
                  {
                    language !== 'EN'
                    && 'Ga naar binnen'
                  }
                  {
                    language === 'EN'
                    && 'Enter'
                  }
                  <i className="fas fa-arrow-right ml-2"></i>
                </button>
              )
            }
            {
              !!registering
              && registering === 'success'
              && (
                <div className="text-white">
                  <span className="text-sm">
                    {
                      language !== 'EN'
                      && 'Gelukt! We zullen je meteen doorverwijzen..'
                    }
                    {
                      language === 'EN'
                      && 'Success! We will redirect you right away..'
                    }
                  </span>
                  <button
                    className="ml-2 border border-transparent focus:outline-none"
                    onClick={() => {
                      setRegistering(false);
                    }}
                  >
                    <i className="fas fa-window-close"></i>
                  </button>
                </div>
              )
            }
            {
              !!registering
              && registering === 'error'
              && (
                <div className="text-white">
                  <span className="text-sm">
                    {
                      language !== 'EN'
                      && 'Er is iets mis gegaan, neem contact op met oscar@focusacademy.com.'
                    }
                    {
                      language === 'EN'
                      && 'Something went wrong, please contact oscar@focusacademy.com.'
                    }
                  </span>
                  <button
                    className="ml-2 border border-transparent focus:outline-none"
                    onClick={() => {
                      setRegistering(false);
                    }}
                  >
                    <i className="fas fa-window-close"></i>
                  </button>
                </div>
              )
            }
          </div>
        </form>

      </div>

      {
        !!pageHTML
        && (
          <div dangerouslySetInnerHTML={{__html: pageHTML }} />
        )
      }

      <div className="flex-grow mx-auto lg:max-w-2xl mt-20">
        
        <div className="pt-5 border-t-2 font-bold pb-2">Test livestream:</div>

        <div>
          {
              mediaPlayerType === 'youtube'
              && (
                <MediaPlayer event={event} demo={true} />
              )
            }

            {
              mediaPlayerType === 'amazon'
              && (
                <AmazonPlayer event={event} demo={true} />
              )
            }
        </div>
      </div>

    </div>
  );
}

RegistrationFormAnonymous.propTypes = {
  event: PropTypes.object.isRequired,
};

export default RegistrationFormAnonymous;
