import React, { useState } from 'react';
import PropTypes from 'prop-types';
import percentRound from 'percent-round';
import { updateEventPoll } from '../../database/events';


function Poll(props) {
  const { event, attendee, isAdmin } = props;
  const { eventKey, pollData } = event.data;
  const pollStatus = event && event.data && event.data.pollStatus
    ? event.data.pollStatus
    : 'off';
  const attendeeKey = attendee && attendee.data && attendee.data.attendeeKey
    ? attendee.data.attendeeKey
    : null;
  const attendeeVote = pollData && pollData[attendeeKey] ? pollData[attendeeKey] : null;
  const [sending, setSending] = useState();


  const fillInPoll = (vote) => {
    console.log(vote);
    if (!vote || !attendeeKey || sending) {
      return;
    }
    setSending(true);
    updateEventPoll({ eventKey, attendeeKey, vote })
      .then(() => {
        // close spinner
        setSending(false);
      })
      .catch(() => {
        // close spinner
        setSending(false);
      })
  }

  const voteCount = [0, 0, 0, 0];
  const allVotes = pollData ? Object.keys(pollData) : [];

  allVotes.forEach(entry => {
    const vote = pollData[entry];
    if (vote === 'A') {
      voteCount[0] = parseInt(voteCount[0] + 1, 10); 
    }
    if (vote === 'B') {
      voteCount[1] = parseInt(voteCount[1] + 1, 10); 
    }
    if (vote === 'C') {
      voteCount[2] = parseInt(voteCount[2] + 1, 10); 
    }
    if (vote === 'D') {
      voteCount[3] = parseInt(voteCount[3] + 1, 10); 
    }
  });

  const votePercentages = percentRound(voteCount);


  if (pollStatus === 'on') {
    return (
      <div className="py-2 bg-gray-800 mb-2 font-bold">
        <div className="px-3 text-gray-200">
          Poll
          {
            !!sending
            && (
              <i className="ml-2 fas fa-cog fa-spin text-lg" />
            )
          }
        </div>
        {
          (
            !!attendeeVote
            || isAdmin
          )
          && (
            <div className="py-2 mx-2 text-gray-800 font-bold flex justify-between shadow">
            <div
              className={
                attendeeVote === 'A'
                  ? 'flex-1 text-center p-2 rounded-l bg-teal-300 ml-1 whitespace-no-wrap underline'
                  : 'flex-1 text-center p-2 rounded-l bg-teal-300 ml-1 whitespace-no-wrap'
              }
              style={{ flexBasis: `${votePercentages[0]}%` }}
            >
              {`A: ${votePercentages[0]}%`}
            </div>
            <div
              className={
                attendeeVote === 'B'
                  ? 'flex-1 text-center p-2 bg-pink-300 whitespace-no-wrap underline'
                  : 'flex-1 text-center p-2 bg-pink-300 whitespace-no-wrap'
              }
              style={{ flexBasis: `${votePercentages[1]}%` }}
            >
              {`B: ${votePercentages[1]}%`}
            </div>
            <div
              className={
                attendeeVote === 'C'
                  ? 'flex-1 text-center p-2 bg-orange-300 whitespace-no-wrap underline'
                  : 'flex-1 text-center p-2 bg-orange-300 whitespace-no-wrap'
              }
              style={{ flexBasis: `${votePercentages[2]}%` }}
            >
              {`C: ${votePercentages[2]}%`}
            </div>
            <div
              className={
                attendeeVote === 'D'
                  ? 'flex-1 text-center p-2 rounded-r bg-purple-300 mr-1 whitespace-no-wrap underline'
                  : 'flex-1 text-center p-2 rounded-r bg-purple-300 mr-1 whitespace-no-wrap'
              }
              style={{ flexBasis: `${votePercentages[3]}%` }}
            >
              {`D: ${votePercentages[3]}%`}
            </div>
          </div>
          )
        }

        {
          
          !attendeeVote
          && !isAdmin
          && (
            <div className="py-2 mx-2 text-gray-700 font-bold flex justify-between">
              <button
                className="text-center p-2 font-bold rounded shadow bg-teal-300 w-full mx-1 hover:shadow-lg hover:bg-teal-400 focus:outline-none"
                onClick={() => {
                  fillInPoll('A');
                }}
              >
                A
              </button>
              <button
                className="text-center p-2 font-bold rounded shadow bg-pink-300 w-full mx-1 hover:shadow-lg hover:bg-pink-400 focus:outline-none"
                onClick={() => {
                  fillInPoll('B');
                }}
              >
                B
              </button>
              <button
                className="text-center p-2 font-bold rounded shadow bg-orange-300 w-full mx-1 hover:shadow-lg hover:bg-orange-400 focus:outline-none"
                onClick={() => {
                  fillInPoll('C');
                }}
              >
                C
              </button>
              <button
                className="text-center p-2 font-bold rounded shadow bg-purple-300 w-full mx-1 hover:shadow-lg hover:bg-purple-400 focus:outline-none"
                onClick={() => {
                  fillInPoll('D');
                }}
              >
                D
              </button>
            </div>
          )

        }
      </div>
    );
  }

  return null;
}


Poll.defaultProps = {
  attendee: null,
}

Poll.propTypes = {
  event: PropTypes.object.isRequired,
  attendee: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
};

export default Poll;
