import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  updateEventPageStatus,
  updateEventChatStatus,
  updateEventURL,
  updateEventPollStatus,
  resetPoll,
} from '../../database/events';

function QuickControl(props) {
  const { event } = props;
  const eventKey = event && event.data && event.data.eventKey
    ? event.data.eventKey
    : null;

  // States
  const pageURLInit = event && event.data && event.data.url
    ? event.data.url
    : '';
  const pageStatus = event && event.data && event.data.pageStatus
    ? event.data.pageStatus
    : 'liveSoon';
  const chatStatus = event && event.data && event.data.chatStatus
    ? event.data.chatStatus
    : 'off';
  const pollStatus = event && event.data && event.data.pollStatus
    ? event.data.pollStatus
    : 'off';
  
  
  const [pageURL, setPageURL] = useState(pageURLInit);
  useEffect(() => {
    setPageURL(pageURLInit);
  }, [pageURLInit]);
  
  return (
    <div className="max-w-6xl text-sm mt-5">

      <table className="table-auto bg-white shadow rounded w-full divide-y divide-gray-200">
        <thead className="bg-gray-100 rounded-t text-sm text-left">
          <tr>
            <th className="p-2 font-medium">Video URL</th>
            <th className="p-2 font-medium">Event status</th>
            <th className="p-2 font-medium">Chat status</th>
            <th className="p-2 font-medium">Poll status</th>
            <th className="p-2 font-medium">Reset poll</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
            <tr>
              <td className="p-2">
                <div className="flex">
                  <input
                    type="text"
                    value={pageURL}
                    className="block w-full text-xs p-1 px-2 bg-gray-200 border-2 border-gray-700 rounded-l focus:outline-none"
                    onChange={(event) => {
                      setPageURL(event.target.value);
                    }}
                  />
                  <button
                    className={
                      pageURL !== pageURLInit
                        ? 'flex-shrink-0 text-sm ml-auto bg-gray-700 text-white py-1 px-2 rounded-r focus:outline-none'
                        : 'cursor-default flex-shrink-0 text-sm ml-auto bg-gray-700 text-white py-1 px-2 rounded-r focus:outline-none'
                    }
                    disabled={pageURL === pageURLInit}
                    onClick={() => {
                      console.log(pageURL);
                      updateEventURL({ eventKey, url: pageURL });
                    }}
                  >
                    {
                      pageURL !== pageURLInit
                      && (
                        <span>Update video</span>
                      )
                    }
                    {
                      pageURL === pageURLInit
                      && (
                        <span><i class="fas fa-check"></i></span>
                      )
                    }
                  </button>
                </div>
              </td>
              <td className="p-2">
                <div className="flex">
                  <select
                    className="block w-full p-1 bg-gray-700 text-white py-1 px-2 rounded focus:outline-none"
                    value={pageStatus}
                    onChange={(event) => {
                      updateEventPageStatus({ eventKey, pageStatus: event.target.value });
                    }}
                  >
                    <option value="liveSoon">Live Soon</option>
                    <option value="liveNow">Live Now</option>
                    <option value="liveDone">Live Done</option>
                  </select>
                </div>
              </td>
              <td className="p-2">
                <div className="flex">
                  <select
                    className="block w-full p-1 bg-gray-700 text-white py-1 px-2 rounded focus:outline-none"
                    value={chatStatus}
                    onChange={(event) => {
                      updateEventChatStatus({ eventKey, chatStatus: event.target.value });
                    }}
                  >
                    <option value="on">Chat On</option>
                    <option value="off">Chat Off</option>
                  </select>
                </div>
              </td>
              <td className="p-2">
                <div className="flex">
                  <select
                    className="block w-full p-1 bg-gray-700 text-white py-1 px-2 rounded focus:outline-none"
                    value={pollStatus}
                    onChange={(event) => {
                      updateEventPollStatus({ eventKey, pollStatus: event.target.value });
                    }}
                  >
                    <option value="on">Poll On</option>
                    <option value="off">Poll Off</option>
                  </select>
                </div>
              </td>
              <td className="p-2">
                <div className="flex">
                  <button
                    className="block w-full p-1 bg-gray-700 text-white py-1 px-2 rounded focus:outline-none"
                    onClick={() => {
                      resetPoll({ eventKey });
                    }}
                  >
                    Reset poll
                  </button>
                </div>
              </td>
            </tr>
        </tbody>
      </table>

    </div>
  );
}

QuickControl.propTypes = {
  event: PropTypes.object.isRequired,
};

export default QuickControl;
