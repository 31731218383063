import React from 'react';
import Header from './Event/Header';
import Footer from './Event/Footer';


function ErrorPage() {

  return (
    <div>
      <Header />

      <div className="min-h-screen">
        <div className="my-10 text-center p-5">
          Helaas, er is iets misgegaan..

          <div className="my-2 font-bold">Neem contact op met michelle@focusacademy.com <br /> om je privé link aan te vragen voor de livestream.</div>
        </div>
      </div>

      <div>
        <Footer />
      </div>

    </div>
  );
}

export default ErrorPage;
