import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import twemoji from 'twemoji';
import linkifyHtml from 'linkifyjs/html';
import { deleteEventMessage, hideEventMessageFromQA, toggleEventMessageLike, updateEventSticky } from '../../../database/events';
import { useUser } from '../../../database/auth';


function Message(props) {
  const { event, isAdmin, attendee, messageData } = props;
  const { eventKey, language } = event.data;
  const user = useUser();

  const userData = isAdmin
    ? {
      name: user.displayName,
      email: user.email || null,
      admin: true,
      attendeeKey: user.uid,
    }
    : {
      name: attendee.data.name,
      attendeeKey: attendee.data.attendeeKey,
      email: attendee.data.email || null,
      admin: false,
    }

  // Add like
  const [liking, setLiking] = useState();
  const like = useCallback(
    ({ messageKey }) => {
      if (!messageKey) {
        return;
      }

      const { attendeeKey } = userData; 

      if (!attendeeKey) {
        console.log('not enough data');
        return;
      }

      // Send message
      setLiking(true);
      toggleEventMessageLike({ eventKey, messageKey, attendeeKey })
        .then((result) => {
          setLiking(false);
        })
        .catch((error) => {
          console.log("Error document:", error);
          setLiking(false);
        });
    },
    [eventKey, userData],
  );

  const { messageKey } = messageData;
  const messageCreator = messageData.userData;

  const userLiked = messageData.likes && messageData.likes[userData.attendeeKey];

  const isAttendeeMessage = !!messageCreator.attendeeKey
    && attendee.data
    && attendee.data.attendeeKey
    && messageCreator.attendeeKey === attendee.data.attendeeKey;

  if (!messageData.message || !messageCreator.name) {
    return null;
  }

  var emojis = {
    ':thumbs-up:': '👍',
    ':thumbs-down:': '👎',
    ':clapping-hands:': '👏',
    ':folded-hands:': '🙏',
    ':waving-hand:': '👋',
    ':brain:': '🧠',
  };
  var re = new RegExp(Object.keys(emojis).join("|"),"gi");
  const messageWithEmoji = messageData.message.replace(re, function(matched){
    return emojis[matched];
  });

  const messageWithEMojis = twemoji.parse(messageWithEmoji, {
    folder: 'svg',
    ext: '.svg'
  });

  const messageWithLinks = linkifyHtml(messageWithEMojis, {
    target: '_blank',
    className: 'text-green-400 hover:underline'
  });


  return (
    <div
      key={messageKey}
      className="group hover:bg-gray-900 rounded p-1 px-2"
    >
      <div className="text-sm lg:text-base" style={{ paddingLeft: '1.5rem', textIndent: '-1.5rem' }}>
        <button
          className={`
            inline-block mr-2 bg-gray-700 text-gray-900 rounded px-1 text-sm leading-normal focus:outline-none
            ${!!userLiked && 'bg-green-400 hover:bg-green-300'}
            ${!userLiked && 'hover:bg-gray-600'}
          `}
          onClick={() => {
            like({ messageKey });
          }}
        >
          {userLiked}
          {
            !!liking
            && (
              <i className="text-xs text-gray-900 fas fa-cog fa-spin" />
            )
          }
          {
            !liking
            && (
              <>
                {
                  !!messageData.totalLikes
                  && (
                    <span className="text-xs font-bold mr-1">{messageData.totalLikes}</span>
                  )
                }
                <i className="far fa-thumbs-up"></i>
              </>
            )
          }
        </button>
        {
          // DELETE BUTTON
          (
            !!isAttendeeMessage
            || !!isAdmin
          )
          && (
            <button
              className="inline-block mr-2 bg-gray-700 hover:bg-gray-600 text-gray-900 rounded px-1 text-sm leading-normal focus:outline-none"
              onClick={() => {
                const sure = language === 'EN'
                  ? window.confirm('Are you sure?')
                  : window.confirm('Weet je het zeker?')

                if (!messageKey || !sure) {
                  return;
                }
                deleteEventMessage({ eventKey, messageKey });
              }}
            >
              <i className="far fa-trash-alt"></i>
            </button>
          )
        }
        {
          // STICKY BUTTON
          (
            !!isAdmin
          )
          && (
            <button
              className="inline-block mr-2 bg-gray-700 hover:bg-gray-600 text-gray-900 rounded px-1 text-sm leading-normal focus:outline-none"
              onClick={() => {
                if (!messageKey) {
                  return;
                }
                updateEventSticky({ eventKey, sticky: messageData });
              }}
            >
              <i className="far fa-sticky-note"></i>
            </button>
          )
        }
        {
          // USER INFO BUTTON
          (
            !!isAdmin
          )
          && (
            <button
              className="inline-block mr-2 bg-gray-700 hover:bg-gray-600 text-gray-900 rounded px-1 text-sm leading-normal focus:outline-none"
              onClick={() => {
                window.prompt('User info', `${ messageCreator.email } - ${messageCreator.attendeeKey}`);
              }}
            >
              <i className="fas fa-user"></i>
            </button>
          )
        }
        {
          // HIDE BUTTON
          !!isAdmin
          && (
            <button
              className="inline-block mr-2 bg-gray-700 hover:bg-gray-600 text-gray-900 rounded px-1 text-sm leading-normal focus:outline-none"
              onClick={() => {
                if (!messageKey) {
                  return;
                }
                hideEventMessageFromQA({ eventKey, messageKey });
              }}
            >
              {
                !messageData.hidden
                && (
                  <i className="far fa-eye-slash"></i>
                )
              }
              {
                !!messageData.hidden
                && (
                  <i class="far fa-eye"></i>
                )
              }
            </button>
          )
        }
        <span className="font-bold mr-2 text-gray-100">{ messageCreator.name }</span>
        <span className="text-gray-300 select-all">
          <span
            dangerouslySetInnerHTML={{
              __html: messageWithLinks,
            }}
          />
        </span>
      </div>
    </div>
  )
}


Message.defaultProps = {
  attendee: null,
}

Message.propTypes = {
  event: PropTypes.object.isRequired,
  attendee: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
  messageData: PropTypes.object.isRequired,
};

export default Message;
