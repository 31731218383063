import React from 'react';
import PropTypes from 'prop-types';
import MediaPlayer from './MediaPlayer';
import AmazonPlayer from './AmazonPlayer';


function LiveSoon(props) {
  const { event } = props;
  const { language } = event.data;

  const pageHTML = event && event.data && event.data.pageHTMLs && event.data.pageHTMLs['liveSoon']
    ? event.data.pageHTMLs['liveSoon']
    : null;
  
  const mediaPlayerType = event && event.data && event.data.mediaPlayerType
    ? event.data.mediaPlayerType
    : 'youtube';


  return (
    <div className="p-5">
      <div className="flex-grow mx-auto lg:max-w-2xl mt-20">
        {
          !!language
          && language === 'EN'
          && 'The event will start automatically, once the livestream begins..'
        }
        {
          !!language
          && language === 'NL'
          && (
            'Het evenement zal automatisch starten, zodra de livestream begint..'
          )
        }
      </div>

      {
        !!pageHTML
        && (
          <div dangerouslySetInnerHTML={{__html: pageHTML }} />
        )
      }

      <div className="flex-grow mx-auto lg:max-w-2xl mt-20">
        
        <div className="pt-5 border-t-2 font-bold pb-2">Test livestream:</div>

        <div>
          {
            mediaPlayerType === 'youtube'
            && (
              <MediaPlayer event={event} demo={true} />
            )
          }

          {
            mediaPlayerType === 'amazon'
            && (
              <AmazonPlayer event={event} demo={true} />
            )
          }
        </div>
      </div>

    </div>
  );
}

LiveSoon.propTypes = {
  event: PropTypes.object.isRequired,
};

export default LiveSoon;
