import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useUser } from '../../../database/auth';
import { updateEventAttendee } from '../../../database/events';

function ChangeName(props) {
  const { event, attendee, isAdmin } = props;
  const { language } = event.data;
  
  const { eventKey } = event && event.data ? event.data : { eventKey: null };
  const user = useUser();
  const [sending, setSending] = useState();
  const userData = isAdmin
    ? {
      name: user.displayName,
      email: user.email || null,
      admin: true,
    }
    : {
      name: attendee.data.name,
      attendeeKey: attendee.data.attendeeKey,
      email: attendee.data.email || null,
      admin: false,
    }

  return (
    <>
      <div className="ml-auto text-right text-xs lg:text-sm">
        {
          !!userData
          && !!userData.name
          && userData.name
        }
        {
          (
            !userData
            || !userData.name
          )
          && (
            language === 'EN'
            ? 'Anonymous'
            : 'Anoniem'
          )
        }
      </div>
      <div className="ml-5">
        <button
          className="text-sm px-2 py-1 border text-gray-500 border-gray-600 rounded hover:border-gray-400 hover:text-gray-300 focus:outline-none"
          onClick={() => {
            if (sending) {
              return;
            }
            const newName = language === 'EN'
              ? window.prompt('What\'s your name?', userData.name)
              : window.prompt('Hoe wil je in de chat genoemd worden?', userData.name)
            if (!newName || typeof newName !== 'string') {
              return;
            }

            setSending(true);

            const newUserData = {
              ...userData,
              name: newName
            };

            if (!newUserData.attendeeKey) {
              setSending(false);
              return;
            }

            updateEventAttendee({ eventKey, userData: newUserData})
              .then((result) => {
                setSending(false);
              })
              .catch((error) => {
                console.log("Error document:", error);
                setSending(false);
              });
          }}
        >
          {
            !!sending
            && (
              <i className="fas fa-cog fa-spin mr-2 text-xs" />
            )
          }
          {
            !!language
            && language === 'EN'
            && 'change'
          }
          {
            !!language
            && language === 'NL'
            && (
              'wijzig'
            )
          }
        </button>
      </div>
    </>
  );
}


ChangeName.defaultProps = {
  attendee: null,
}


ChangeName.propTypes = {
  event: PropTypes.object.isRequired,
  attendee: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
};

export default ChangeName;
