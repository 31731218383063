import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import ejs from 'ejs';
import moment from 'moment';
import {
  sendAttendeesMail,
  useEventAttendees,
} from '../../database/events';

function Email(props) {
  const { event } = props;
  const eventKey = event && event.data && event.data.eventKey
    ? event.data.eventKey
    : null;
  const eventName = event && event.data && event.data.name
  ? event.data.name
  : null;

  // Attendees
  const attendees = useEventAttendees({ eventKey });
  const [selected, setSelected] = useState({});

  const attendeesCount = attendees && attendees.data && attendees.data.length ? attendees.data.length : 0;
  const selectedCount = selected ? Object.keys(selected).length : 0;

  const [template, setTemplate] = useState(
`<!DOCTYPE html
  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html>

<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta name="x-apple-disable-message-reformatting" />
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  <title>FocusAcademy.live - Persoonlijke Link</title>
</head>

<body style="font-size:10pt; font-family:Arial;">
  <div>Hi <%= name %>,<br><br>

    Je hebt je ingeschreven voor het live online event "${eventName}".<br>

    Met deze link log je direct in: <a href="http://focusacademy.live/e/${eventKey}/u/<%= key %>" style="color:rgb(40,54,123);">focusacademy.live/e/${eventKey}/u/<%= key %></a>.<br><br>

    <strong>Het programma</strong><br>
    09:30 - 10:00 - Technische check & inloop<br>
    10:00 - 10:45 - Live webinar<br>
    10:45 - 11:00 - Q&A<br><br>
    
    <strong>Technische check</strong><br>
    - Je hoeft niets te downloaden voor deze livestream.<br>
    - Voor de livestream heb je één van de volgende browsers nodig: Chrome (55+), Firefox (53+), Safari (12.1+) of Microsoft Edge (42+).<br>
    - Check hier vooraf welke browser je gebruikt: www.whatsmybrowser.org.<br>
    - Bekijk hieronder de livestream van NASA, om te testen of de techniek werkt.<br><br>
      
    Met vriendelijke groet,<br><br>

    <div>
      <span style="font-weight:700; color:rgb(40,54,123);">Michelle Vonk</span><br>
      <span>Focus expert / trainer</span><br><br>
      <span style="color:rgb(0,242,148);">___</span><br><br>
      <span>+ 31 (0) 20 261 0076</span><br>
      <span>+ 31 (0) 6 466 272 40</span><br>
      <a href="https://www.linkedin.com/in/michellevonk" style="color:rgb(40,54,123);">linkedin.com/in/michellevonk</span><br>
      <a href="http://www.focusacademy.com" style="color:rgb(40,54,123);">www.focusacademy.com</a><br>
    </div>
  </div>
</body>

</html>`
  );

  // loop all selected, create html with ejs and vars.
  const receivers = selectedCount
    ? Object.keys(selected).map((receiver) => {
      const { email } = selected[receiver];
      return {
        email,
        html: ejs.render(template, selected[receiver]),
      }
    })
    : null;
  const [previewReceiver, setPreviewReceiver] = useState();

  const previewHTML = receivers && receivers[previewReceiver] && receivers[previewReceiver].html
    ? receivers[previewReceiver].html
    : template;

  const [subject, setSubject] = useState('');
  const [fromEmail, setFromEmail] = useState('michelle@focusacademy.com');
  const [sending, setSending] = useState(false);

  
  return (
    <div className="bg-white shadow rounded p-5 mt-5">
      <div>
        <table className="table-auto bg-white shadow rounded w-full divide-y divide-gray-200">
          <thead className="bg-gray-100 rounded-t text-sm text-left">
            <tr>
              <th className="p-2 font-medium w-32">
                {
                  attendeesCount === selectedCount
                  && (
                    <button
                      className="bg-gray-700 text-white py-1 px-2 rounded focus:outline-none"
                      onClick={() => {
                        setSelected({});
                      }}
                    >
                      Deselect all
                    </button>
                  )
                }
                {
                  attendeesCount !== selectedCount
                  && (
                    <button
                      className="bg-gray-700 text-white py-1 px-2 rounded focus:outline-none"
                      onClick={() => {
                        if (!attendees || !attendees.data || !attendees.data.length) {
                          return;
                        }
                        const newSelected = {};
                        attendees.data.forEach(attendee => {
                          const { attendeeKey } = attendee;
                          newSelected[attendeeKey] = attendee;
                        });
                        setSelected(newSelected);
                      }}
                    >
                      Select all
                    </button>
                  )
                }
              </th>
              <th className="p-2 font-medium">Attendee Key</th>
              <th className="p-2 font-medium">Name</th>
              <th className="p-2 font-medium">Email</th>
              <th className="p-2 font-medium">Link</th>
              <th className="p-2 font-medium">Added</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {
              !!attendees
              && !!attendees.data
              && !!attendees.data.length
              && attendees.data.map(attendee => {
                const { name, email, attendeeKey, created } = attendee;
                return (
                  <tr
                    className={
                      selected && selected[attendeeKey]
                        ? 'cursor-pointer hover:shadow-lg bg-green-100'
                        : 'cursor-pointer hover:shadow-lg'
                    }
                    onClick={() => {
                      const newSelected = {
                        ...selected,
                      }

                      // If already exist, remove
                      if (selected && newSelected[attendeeKey]) {
                        delete newSelected[attendeeKey];
                        setSelected(newSelected);
                        return;
                      }

                      // If not exist, add to selected
                      newSelected[attendeeKey] = {
                        ...attendee,
                      }
                      setSelected(newSelected);
                    }}
                  >
                    <td className="p-2 font-bold text-2xl">
                      {
                        !!selected
                        && !!selected[attendeeKey]
                        && (
                          <i className="text-green-600 far fa-toggle-on"></i>
                        )
                      }
                      {
                        (
                          !selected
                          || !selected[attendeeKey]
                        )
                        && (
                          <i className="far fa-toggle-off"></i>
                        )
                      }
                    </td>
                    <td className="p-2 text-xs font-mono select-all">
                      {attendeeKey}
                    </td>
                    <td className="p-2 font-bold">
                      { name }
                    </td>
                    <td className="p-2">
                      { email }
                    </td>
                    <td className="p-2 text-xs font-mono select-all">
                      {`http://focusacademy.live/e/${eventKey}/u/${attendeeKey}`}
                    </td>
                    <td className="p-2">
                      {
                        !!created
                        && (
                          moment(created.toDate()).format('D-MM-YYYY HH:mm')
                        )
                      }
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>

      </div>
      <div className="mt-10">
        <div className="flex">
          <div className="w-1/2 mr-5">
            <AceEditor
              placeholder="Hello world"
              mode="html"
              theme="monokai"
              name="template"
              onChange={(newValue) => {
                setTemplate(newValue)
              }}
              fontSize={14}
              wrapEnabled={true}
              maxLines={Infinity}
              width={'100%'}
              showGutter={true}
              showPrintMargin={false}
              highlightActiveLine={true}
              value={template}
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
          </div>
          <div className="w-1/2">
            <div className="mb-2">
              <input
                type="text"
                className="border rounded p-2 w-full focus:outline-none text-sm"
                value={previewReceiver}
                placeholder="Preview number 0, 1, 2"
                onChange={(e) => {
                  setPreviewReceiver(e.target.value);
                }}
              />
            </div>
            <div className="p-5 border-2 rounded">
              <div dangerouslySetInnerHTML={{__html: previewHTML}} />
            </div>
          </div>
        </div>

      </div>
      

      <div className="max-w-lg mx-auto mt-10">

        <div className="text-center">
          <div>
            <input
              type="text"
              className="border rounded p-2 w-full focus:outline-none text-sm"
              value={subject}
              placeholder="Email subject"
              onChange={(e) => {
                setSubject(e.target.value);
              }}
            />
          </div>
          <div className="mt-2">
            <input
              type="text"
              className="border rounded p-2 w-full focus:outline-none text-sm"
              value={fromEmail}
              placeholder="From email"
              onChange={(e) => {
                setFromEmail(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="p-5 text-center">
          {
            !!receivers
            && receivers.length
            && (
              <button
                className="bg-gray-700 text-white py-2 px-4 rounded focus:outline-none"
                onClick={() => {
                  if(sending || !receivers || !receivers.length || !subject || !fromEmail ) {
                    return;
                  }

                  setSending(true);

                  sendAttendeesMail({ receivers, subject, fromEmail }).then(() => {
                    setSending(false);
                    window.alert('Great success!!');
                  })
                  .catch((error) => {
                    setSending(false);
                    console.log(error);
                    window.alert('Error, contact Oscar.');
                  })
                }}
              >
                {
                  !sending
                  && (
                    <span>Send to {receivers.length} person(s)</span>
                  )
                }
                {
                  !!sending
                  && (
                    <span><i className="fas fa-cog fa-spin mr-2" /> Sending to {receivers.length} person(s)</span>
                  )
                }
              </button>
            )
          }
          
          {
            (
              !receivers
              || !receivers.length
            )
            && (
              <div>
                Please select some attendees
              </div>
            )
          }
          

        </div>

      </div>
      
    </div>
  );
}

Email.propTypes = {
  event: PropTypes.object.isRequired,
};

export default Email;
