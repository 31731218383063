import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';


function MediaPlayer(props) {
  const { event, demo } = props;

  const url = event && event.data && event.data.url && !demo
    ? event.data.url
    : 'https://www.youtube.com/watch?v=DDU-rZs-Ic4';

  const helpInstructions = event && event.data && event.data.helpInstructions
    ? event.data.helpInstructions
    : null

  const pageStatus = event && event.data && event.data.pageStatus
    ? event.data.pageStatus
    : 'liveSoon';

  const [loading, setLoading] = useState(true);
  const [hiding, setHiding] = useState(false);
  const [muted, setMuted] = useState(true);
  const [playing, setPlaying] = useState(false);
  const playerRef = useRef(null);

  return (
    <div>
      <div className="relative aspect-ratio-box">

        <button
          onClick={() => {
            playerRef.current.seekTo(1, 'fraction');
            setHiding(true);
            setPlaying(true);
          }}
          className={ loading || !!playing ? 'hidden' : 'z-50 flex absolute top-0 left-0 w-full h-full justify-center content-center bg-gray-900 items-center text-4xl text-gray-400 hover:text-green-400 focus:outline-none'}>
          <div className="text-center">
              <i className="far fa-play-circle"></i>
          </div>
        </button>


        <button
          onClick={() => {
            setHiding(false);
            setPlaying(false);
          }}
          className={ !!loading || hiding || !playing ? 'hidden' : 'z-50 flex absolute top-0 left-0 w-full h-full justify-center content-center bg-transparent items-center text-4xl text-transparent hover:text-red-400 focus:outline-none'}>
          <div className="text-center">
              <i className="far fa-stop-circle"></i>
          </div>
        </button>


        <div className={ !loading && !hiding ? 'hidden' : 'z-50 flex absolute bg-gray-900 top-0 left-0 w-full h-full justify-center content-center items-center'}>
          <div className="text-gray-500 text-center">
            <i className="fas fa-cog fa-spin" />
          </div>
        </div>
        
        
        <div className={
          !!loading || hiding || !playing ? 'hidden' : 'block'
        }>
          <ReactPlayer
            className="absolute top-0 left-0 pointer-events-none"
            ref={playerRef}
            url={url}
            width='100%'
            height='100%'
            config={{
              youtube: {
                playerVars: { modestbranding: 1 }
              },
            }}
            muted={muted}
            playing={playing}
            onPlay={() => {
              console.log('onPlay');
              setTimeout(function(){
                setMuted(false);
                setHiding(false);
              }, 3000);
            }}
            onReady={() => {
              console.log('onReady');
              setLoading(false);
              setPlaying(false);
              setMuted(true);
            }}
            onEnded={() => {
              console.log('onEnded');
              setHiding(true);
            }}
            onPause={() => {
              console.log('onPause');
              setHiding(false);
              setMuted(true);
              setPlaying(false);
            }}
            onError={() => {
              console.log('onError');
            }}
          />
        </div>
      </div>
      <div className="p-3 bg-gray-800 flex justify-between items-center">
        <div className="text-gray-600 text-sm">
          {
            !!helpInstructions
            && pageStatus === 'liveNow'
            && (
              <div dangerouslySetInnerHTML={{ __html: helpInstructions }} />
            )
          }
          {
            !helpInstructions
            && pageStatus === 'liveNow'
            && (
              <>
                Voor technische ondersteuning mail naar: <a className="font-bold hover:underline" href="mailto:sandra@focusacademy.com">sandra@focusacademy.com</a>
              </>
            )
          }
        </div>
        <button
          className="text-xs px-2 py-1 border text-gray-500 border-gray-600 rounded hover:border-gray-400 hover:text-gray-300 focus:outline-none"
          onClick={() => {
            window.location.reload(false);
          }}
        >
          refresh
        </button>
      </div>
    </div>
  );
}

MediaPlayer.propTypes = {
  event: PropTypes.object.isRequired,
};

export default MediaPlayer;