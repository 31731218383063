import React from 'react';
import PropTypes from 'prop-types';
import { useEventMessagesHidden } from '../../database/events';
import Message from './Chat/Message';


function HiddenMessages(props) {
  const { event, attendee, isAdmin } = props;
  const { eventKey } = event.data;
  const messages = useEventMessagesHidden({ eventKey });

  return (
    <div>

      {
        !!messages
        && !!messages.loading
        && (
          <div className="text-gray-500 text-center">
            <i className="fas fa-cog fa-spin" />
          </div>
        )
      }

      {
        !!messages
        && !!messages.data
        && !!messages.data.length
        && (
          messages.data.map((messageData) => {
            return <Message key={messageData.messageKey} event={event} attendee={attendee} isAdmin={isAdmin} messageData={messageData} />
          })
        )
      }
    </div>
  );
}


HiddenMessages.defaultProps = {
  attendee: null,
}

HiddenMessages.propTypes = {
  event: PropTypes.object.isRequired,
  attendee: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
};

export default HiddenMessages;
