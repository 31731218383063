import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import nl from 'date-fns/locale/nl';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import { updateEventData } from '../../database/events';

registerLocale('nl', nl);

function ListEvent(props) {
  const { key, name, startDateTime, pageStatus, language } = props.event;
  const [loadingName, setLoadingName] = React.useState();
  const [loadingDate, setLoadingDate] = React.useState();

  const startDateTimePicker = !!startDateTime
    ? new Date(startDateTime)
    : new Date()

  return (
    <tr>
      <td className="p-2">
        <button
          type="button"
          className="text-left w-full hover:underline text-sm font-bold focus:outline-none"
          onClick={() => {
            const newName = window.prompt('Change title', name);
            if (!newName || typeof newName !== 'string') {
              return;
            }

            // Change name in the database
            setLoadingName(true);
            updateEventData({ eventKey: key, data: { name: newName } })
              .then(() => {
                setLoadingName(false);
              });
          }}
        >
          {
            !!loadingName
            && <i className="fas fa-cog fa-spin"></i>
          }
          {
            !loadingName
            && name
          }
        </button>
      </td>
      <td className="p-2 whitespace-no-wrap">
        {
          !!loadingDate
          && (
            <div className="w-48 mr-auto rounded py-1 px-2 text-sm focus:outline-none">
              <i className="fas fa-cog fa-spin"></i>
            </div>
          )
        }
        {
          !loadingDate
          && (
            <DatePicker
              selected={startDateTimePicker}
              locale="nl"
              showTimeSelect
              dateFormat="dd MMMM yyyy, HH:mm"
              className="w-48 cursor-pointer hover:underline rounded p-0 text-sm focus:outline-none"
              onChange={(date) => {
                setLoadingDate(true);
                updateEventData({ eventKey: key, data: { startDateTime: date.valueOf() } })
                  .then(() => {
                    setLoadingDate(false);
                  });
              }}
            />
          )
        }
      </td>
      <td className="p-2 whitespace-no-wrap">
        { language }
      </td>
      <td className="p-2 whitespace-no-wrap">
        {
          (
            !pageStatus
            || pageStatus === 'liveSoon'
          )
          && (
            <span className="inline-block text-sm py-1 px-2 rounded bg-green-400 text-green-800">
              event soon
            </span>
          )
        }
        {
          !!pageStatus
          && pageStatus === 'liveNow'
          && (
            <span className="inline-block text-sm py-1 px-2 rounded bg-red-400 text-red-800">
              event live
            </span>
          )
        }
        {
          !!pageStatus
          && pageStatus === 'liveDone'
          && (
            <span className="inline-block text-sm py-1 px-2 rounded bg-gray-200 text-gray-600">
              event done
            </span>
          )
        }
      </td>
      <td className="p-2 whitespace-no-wrap">
        <Link
          className="inline-block bg-gray-700 text-white py-1 px-2 text-sm rounded focus:outline-none"
          to={`/admin/e/${key}`}
        >
          admin page <i className="fas fa-angle-right ml-1"></i>
        </Link>
      </td>
      <td className="p-2 whitespace-no-wrap">
        <Link
          className="inline-block bg-gray-700 text-white py-1 px-2 text-sm rounded focus:outline-none"
          to={`/e/${key}/u/admin`}
        >
          live page <i className="fas fa-angle-right ml-1"></i>
        </Link>
      </td>
    </tr>
  );
}

ListEvent.propTypes = {
  event: PropTypes.object.isRequired,
};

export default ListEvent;
