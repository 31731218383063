import React from 'react';
import { useContext } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';

const provider = new firebase.auth.GoogleAuthProvider();


export const userContext = React.createContext({
  user: null,
});

export const login = () => {
  firebase.auth().signInWithPopup(provider);
};

export const loginAnonymously = () => {
  firebase.auth().signInAnonymously();
};

export const logout = () => {
  firebase.auth().signOut();
};

export const useAuth = () => {
  const [state, setState] = React.useState(() => {
    const user = firebase.auth().currentUser;
    return {
      initializing: !user,
      user,
    };
  });
  function onChange(user) {
    setState({
      initializing: false,
      user,
    });
  }

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange);
    return () => unsubscribe();
  }, []);

  return state;
};

export const useUser = () => {
  const { user } = useContext(userContext);
  return user;
};
