import React from 'react';
import PropTypes from 'prop-types';
import ChangeName from './change-name';

function Header(props) {
  const { event, attendee, isAdmin } = props;

  return (
    <div className="flex p-3 px-8 text-gray-400 bg-gray-800 items-center justify-between">
      <div>
        <div className="text-gray-200">
          <span className="font-bold">Focus Academy</span> <span className="text-red-500">Live</span>
        </div>
      </div>
        {
          !!event
          && !!attendee
          && (
            <ChangeName event={event} attendee={attendee} isAdmin={isAdmin} />
          )
        }
    </div>
  );
}


Header.defaultProps = {
  attendee: null,
  event: null,
  isAdmin: false,
}


Header.propTypes = {
  event: PropTypes.object,
  attendee: PropTypes.object,
  isAdmin: PropTypes.bool,
};

export default Header;
