import React from 'react';
import * as firebase from 'firebase/app';
import { db, functions } from '.';




// Create event
export function createEvent({ name, startDateTime, eventKey, language }) {

  return db.collection('events').doc(eventKey).get()
      .then(docSnapshot => {
        if (docSnapshot.exists) {
          return null;
        }
        return db.collection('events').doc(eventKey).set({
          name,
          startDateTime,
          language,
        }, { merge: true });
      });
}


// Update event data
export function updateEventData({ eventKey, data }) {
  return db.collection('events').doc(eventKey).update({
    ...data,
  });
}

// Update Event page status
export function updateEventPageStatus({ eventKey, pageStatus }) {
  return db.collection('events').doc(eventKey).update({
    pageStatus,
  });
}

// Update Event chat status
export function updateEventChatStatus({ eventKey, chatStatus }) {
  return db.collection('events').doc(eventKey).update({
    chatStatus,
  });
}

// Update Event Q&A status
export function updateEventQAStatus({ eventKey, qaStatus }) {
  return db.collection('events').doc(eventKey).update({
    qaStatus,
  });
}

// Update Event poll status
export function updateEventPollStatus({ eventKey, pollStatus }) {
  return db.collection('events').doc(eventKey).update({
    pollStatus,
  });
}

// Reset poll 
export function resetPoll({ eventKey }) {
  return db.collection('events').doc(eventKey).update({
    pollData: null,
  });
}

// Update Event url
export function updateEventURL({ eventKey, url }) {
  return db.collection('events').doc(eventKey).update({
    url,
  });
}

// Update Event pageHTMLs
export function updateEventPageHTMLs({ eventKey, pageHTMLs }) {
  return db.collection('events').doc(eventKey).update({
    pageHTMLs,
  });
}

export function updateEventSticky({ eventKey, sticky }) {
  return db.collection('events').doc(eventKey).update({
    sticky,
  });
}


// Update Event poll
export function updateEventPoll({ eventKey, attendeeKey, vote }) {
  if (!attendeeKey) {
    return;
  }
  if (
    !vote
    || !(
      vote === 'A'
      || vote === 'B'
      || vote === 'C'
      || vote === 'D'
    )
  ) {
    console.log('No correct vote');
    return;
  }

  const eventRef = db.collection('events').doc(eventKey);

  return db.runTransaction((transaction) => {

      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(eventRef).then(function(eventDoc) {
          if (!eventDoc.exists) {
            return;
          }
          const event = eventDoc.data();
          const currentPollData = event.pollData || {};

          const newPollData = {
            ...currentPollData
          };
          newPollData[attendeeKey] = vote;

          transaction.update(eventRef, { pollData: newPollData });
      });
  });
}


// Create open registration
export function createOpenRegistration({ firstName, lastName, email, eventKey, eventTitle, eventLanguage }) {
  const openRegistration = functions.httpsCallable('openRegistration');
  return openRegistration({ firstName, lastName, email, eventKey, eventTitle, eventLanguage }).then((result) => {
    console.log(result);
  });
};

// Create anonymous registration
export function createAnonymousOpenRegistration({ name, eventKey, eventTitle, eventLanguage }) {
  const openAnonymousRegistration = functions.httpsCallable('openAnonymousRegistration');
  return openAnonymousRegistration({ name, eventKey, eventTitle, eventLanguage });
};



// Send Bulk email
export function sendAttendeesMail({ receivers, subject, fromEmail }) {
  const sendBulkMail = functions.httpsCallable('sendBulkMail');
  return sendBulkMail({ receivers, subject, fromEmail }).then((result) => {
    console.log(result);
  });
};


// Send Bulk email
export function sendFocusPlan({ email, optin, focusPlan }) {
  const sendFocusPlanFunction = functions.httpsCallable('sendFocusPlan');
  return sendFocusPlanFunction({ email, optin, focusPlan });
};


// Add Event attendees
export function addEventAttendees({ eventKey, newAttendees }) {
  if(!newAttendees || !newAttendees.length) {
    return;
  }

  const batch = db.batch();

  newAttendees.forEach((newAttendee, index) => {
    if(!newAttendee || !newAttendee.attendeeKey) {
      return;
    }
    var newAttendeeRef = db.collection('events').doc(eventKey).collection('attendees').doc(newAttendee.attendeeKey);
    batch.set(
      newAttendeeRef, {
        ...newAttendee,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        index,
      });
  });

  return batch.commit();
}


export function updateEventAttendeeFocusPlan({ eventKey, attendeeKey, focusPlan }) {
  return db.collection('events').doc(eventKey).collection('attendees').doc(attendeeKey).update({ focusPlan });
}

export function updateEventAttendeeMinChat({ eventKey, attendeeKey, minChat }) {
  return db.collection('events').doc(eventKey).collection('attendees').doc(attendeeKey).update({ minChat });
}

export function updateEventAttendeeMinQA({ eventKey, attendeeKey, minQA }) {
  return db.collection('events').doc(eventKey).collection('attendees').doc(attendeeKey).update({ minQA });
}

export function updateEventAttendeeMinFocusPlan({ eventKey, attendeeKey, minFocusPlan }) {
  return db.collection('events').doc(eventKey).collection('attendees').doc(attendeeKey).update({ minFocusPlan });
}

// Save attendee fingerprint
export function updateEventAttendeeFingerprint({ eventKey, attendeeKey, fingerprint }) {
  const attendeeRef = db.collection('events').doc(eventKey).collection('attendees').doc(attendeeKey);

  return db.runTransaction((transaction) => {
    return transaction.get(attendeeRef).then((attendeeDoc) => {
      if (!attendeeDoc.exists) {
        throw new Error('Document does not exist!');
      }

      const attendee = attendeeDoc.data();

      // Add like toggle
      attendee.fingerprints = attendee.fingerprints || [];
      attendee.fingerprints.push(fingerprint);

      transaction.update(attendeeRef, attendee);
    });
  });
};

export function deleteEventAttendee({ eventKey, attendeeKey }) {
  return db.collection('events').doc(eventKey).collection('attendees').doc(attendeeKey).delete();
}


// Get 1 event
export function useEvent({ eventKey }) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);

  React.useEffect(
    () => {
      setLoading(true);
      setError(false);
      const unsubscribe = firebase
        .firestore()
        .collection('events')
        .doc(eventKey)
        .onSnapshot((doc) => {
          const data = doc.data();
          if (!data) {
            setData(null);
            setError(true);
          }
          if (data) {
            setData({
              eventKey,
              ...data,
            });
          }
          setLoading(false);
        }, (err) => {
          setError(err);
        });
      return () => unsubscribe();
    },
    [eventKey],
  );

  return {
    error,
    loading,
    data,
  };
}


// Get all events
export function useEvents() {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);

  React.useEffect(
    () => {
      setLoading(true);
      setError(false);
      const unsubscribe = firebase
        .firestore()
        .collection('events')
        .onSnapshot((snapshot) => {
          
          const events = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            events.unshift({
              key: doc.id,
              ...data,
            });
          });

          // Sort events
          events.sort((a, b) => {

            // If one has timestamp
            if ( !a.startDateTime && !!b.startDateTime ){
              return 1;
            }
            if ( !!a.startDateTime && !b.startDateTime ){
              return -1;
            }

            // If none has timestamp
            if (
              !a.startDateTime
              || !b.startDateTime
            ) {
              return 0;
            }

            // Sort on timestamp value
            if ( a.startDateTime < b.startDateTime ){
              return 1;
            }
            if ( a.startDateTime > b.startDateTime ){
              return -1;
            }

            return 0;
          });

          setData(events);
          setLoading(false);
        }, (err) => {
          setError(err);
        });
      return () => unsubscribe();
    },
    [],
  );

  return {
    error,
    loading,
    data,
  };
}



// Get 1 attendee
export function useEventAttendee({ eventKey, attendeeKey }) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);

  React.useEffect(
    () => {
      setLoading(true);
      setError(false);

      if (!attendeeKey) {
        setLoading(false);
        setError(false);
        setData(null);
        return null;
      }

      const unsubscribe = firebase
        .firestore()
        .collection('events')
        .doc(eventKey)
        .collection('attendees')
        .doc(attendeeKey)
        .onSnapshot((doc) => {
          const data = doc.data();
          if (!data) {
            setData(null);
            setError(true);
          }
          if (data) {
            setData({
              attendeeKey,
              ...data,
            });
          }
          setLoading(false);
        }, (err) => {
          setError(err);
        });
      return () => unsubscribe();
    },
    [eventKey, attendeeKey],
  );

  return {
    error,
    loading,
    data,
  };
}


// Get all event attendees
export function useEventAttendees({ eventKey }) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);

  React.useEffect(
    () => {
      setLoading(true);
      setError(false);
      const unsubscribe = firebase
        .firestore()
        .collection('events')
        .doc(eventKey)
        .collection('attendees')
        .onSnapshot((snapshot) => {

          const attendees = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            attendees.unshift({
              key: doc.id,
              ...data,
            });
          });
          
          attendees.sort((a, b) => {

            if (a.created && !b.created){
              return -1;
            }
            if (!a.created && b.created){
              return 1;
            }

            // If no created, stay the same
            if (
              !a.created
              && !b.created
            ) {
              return 0;
            }

            // Sort on timestamp value
            if ( a.created.valueOf() < b.created.valueOf() ){
              return 1;
            }
            if ( a.created.valueOf() > b.created.valueOf() ){
              return -1;
            }

            // Sort on index
            if ( a.index < b.index ){
              return -1;
            }
            if ( a.index > b.index ){
              return 1;
            }

            return 0;
          });
          setData(attendees);
          setLoading(false);
        }, (err) => {
          setError(err);
        });
      return () => unsubscribe();
    },
    [eventKey],
  );

  return {
    error,
    loading,
    data,
  };
}


// Update attendee
export function updateEventAttendee({ eventKey, userData }) {
  return db.collection('events').doc(eventKey).collection('attendees').doc(userData.attendeeKey).update({
    ...userData
  });
}



// Add a message
export function addEventMessage({ eventKey, userData, message }) {
  return db.collection('events').doc(eventKey).collection('messages').add({
    created: firebase.firestore.FieldValue.serverTimestamp(),
    userData,
    message
  });
}

// Add a like to a message
export function toggleEventMessageLike({ eventKey, messageKey, attendeeKey }) {
  const messageRef = db.collection('events').doc(eventKey).collection('messages').doc(messageKey);

  return db.runTransaction((transaction) => {
    return transaction.get(messageRef).then(function(messageDoc) {
        if (!messageDoc.exists) {
          throw new Error('Document does not exist!');
        }

        const message = messageDoc.data();

        // Add like toggle
        message.likes =  message.likes || {};
        if (!!message.likes[attendeeKey]) {
          delete message.likes[attendeeKey]
        }
        else {
          message.likes[attendeeKey] = true
        }

        // Add count
        message.totalLikes = Object.keys(message.likes).length;

        transaction.update(messageRef, message);
    });
  }).then(function() {
      console.log("Transaction successfully committed!");
  }).catch(function(error) {
      console.log("Transaction failed: ", error);
  });
}


// Delete a message
export function deleteEventMessage({ eventKey, messageKey }) {
  return db.collection('events').doc(eventKey).collection('messages').doc(messageKey).delete();
}


// Hide a message from Q&A
export function hideEventMessageFromQA({ eventKey, messageKey }) {
  // return db.collection('events').doc(eventKey).collection('messages').doc(messageKey).delete();
  const messageRef = db.collection('events').doc(eventKey).collection('messages').doc(messageKey);

  return db.runTransaction((transaction) => {
    return transaction.get(messageRef).then(function(messageDoc) {
        if (!messageDoc.exists) {
          throw new Error('Document does not exist!');
        }

        const message = messageDoc.data();

        // Add like toggle
        message.hidden = !message.hidden;

        transaction.update(messageRef, message);
    });
  }).then(function() {
      console.log("Transaction successfully committed!");
  }).catch(function(error) {
      console.log("Transaction failed: ", error);
  });
}



// Get 1000 latest chat massages
export function useEventMessages({ eventKey }) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);

  React.useEffect(
    () => {
      setLoading(true);
      setError(false);
      const unsubscribe = firebase
        .firestore()
        .collection('events')
        .doc(eventKey)
        .collection('messages')
        .orderBy('created', 'desc')
        .limit(1000)
        .onSnapshot((snapshot) => {
          const messages = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            messages.unshift({
              messageKey: doc.id,
              ...data,
            });
          });
          setData(messages);
          setLoading(false);
        }, (err) => {
          setError(err);
        });
      return () => unsubscribe();
    },
    [eventKey],
  );

  return {
    error,
    loading,
    data,
  };
}


// Get 10 most popular chat massages
export function useEventMessagesMostLiked({ eventKey }) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);

  React.useEffect(
    () => {
      setLoading(true);
      setError(false);
      const unsubscribe = firebase
        .firestore()
        .collection('events')
        .doc(eventKey)
        .collection('messages')
        .orderBy('totalLikes', 'desc')
        .limit(1000)
        .onSnapshot((snapshot) => {
          const messages = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            messages.unshift({
              messageKey: doc.id,
              ...data,
            });
          });
          setData(messages);
          setLoading(false);
        }, (err) => {
          setError(err);
        });
      return () => unsubscribe();
    },
    [eventKey],
  );

  return {
    error,
    loading,
    data,
  };
}


// Get all hidden messages
export function useEventMessagesHidden({ eventKey }) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);

  React.useEffect(
    () => {
      setLoading(true);
      setError(false);
      const unsubscribe = firebase
        .firestore()
        .collection('events')
        .doc(eventKey)
        .collection('messages')
        .where('hidden', '==', true)
        .onSnapshot((snapshot) => {
          const messages = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            messages.unshift({
              messageKey: doc.id,
              ...data,
            });
          });
          setData(messages);
          setLoading(false);
        }, (err) => {
          setError(err);
        });
      return () => unsubscribe();
    },
    [eventKey],
  );

  return {
    error,
    loading,
    data,
  };
}


// Get all focus-plan subscriptions
export function useFocusPlanMails() {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);

  console.log('load focus plan mails')

  React.useEffect(
    () => {
      setLoading(true);
      setError(false);
      const unsubscribe = firebase
        .firestore()
        .collection('focus-plan-mails')
        .onSnapshot((snapshot) => {
          console.log(snapshot)
          const mails = [];
          snapshot.forEach((doc) => {
            console.log(doc)
            const data = doc.data();
            mails.unshift({
              key: doc.id,
              ...data,
            });
          });
          setData(mails);
          setLoading(false);
        }, (err) => {
          console.log(err);
          setError(err);
        });
      return () => unsubscribe();
    },
    [],
  );

  return {
    error,
    loading,
    data,
  };
}