import React from 'react';
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import nl from 'date-fns/locale/nl';
import "react-datepicker/dist/react-datepicker.css";
import { createEvent } from '../../database/events';

registerLocale('nl', nl);

function CreateEvent() {
  const [newName, setNewName] = React.useState('');
  const [newDate, setNewDate] = React.useState(new Date());
  const [newLanguage, setNewLanguage] = React.useState("NL");
  const [loading, setLoading] = React.useState();

  // Slugify
  const newSlug = newName
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')

  const startDateTimePicker = !!newDate
    ? new Date(newDate)
    : new Date()

  return (
    <div className="mb-10">
      <h2 className="mb-2 font-bold">Create event</h2>
      <div className="bg-white shadow rounded p-3">
        <div className="flex items-center">
          <div className="mr-2 flex-grow">
            <div>
              <input
                type="text"
                className="border rounded p-2 w-full focus:outline-none text-sm"
                value={newName}
                placeholder="Event name"
                disabled={loading}
                onChange={(e) => {
                  setNewName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="mx-2 flex-shrink-0">
            <DatePicker
              selected={startDateTimePicker}
              locale="nl"
              showTimeSelect
              dateFormat="dd MMMM yyyy, HH:mm"
              className={
                loading
                ? 'w-48 text-gray-500 py-1 px-2 text-sm focus:outline-none'
                : 'w-48 cursor-pointer hover:underline py-1 px-2 text-sm focus:outline-none'
              }
              disabled={loading}
              onChange={(date) => {
                setNewDate(date.valueOf())
              }}
            />
          </div>
          <div className="ml-2 flex-shrink-0">
            <select
              className="block w-full p-1 bg-gray-300  py-1 px-2 rounded focus:outline-none"
              value={newLanguage}
              onChange={(event) => {
                setNewLanguage(event.target.value);
              }}
            >
              <option value="NL">NL</option>
              <option value="EN">EN</option>
            </select>
          </div>
          <div className="ml-2 flex-shrink-0">
            <button
              className={
                loading
                  ? 'bg-gray-300 cursor-wait py-1 px-2 text-sm rounded focus:outline-none'
                  : 'bg-gray-300 hover:bg-gray-400 py-1 px-2 text-sm rounded focus:outline-none'
              }
              onClick={() => {
                if (!newName || !newDate) {
                  return;
                }
                setLoading(true);
                createEvent({ name: newName, startDateTime: newDate.valueOf(), eventKey: newSlug, language: newLanguage })
                  .then(() => {
                    setLoading(false);
                    setNewName('');
                    setNewDate(null);
                  });
              }}
              disabled={loading}
            >
              {
                !!loading
                && (
                  <span>
                    create event
                    <i className="fas fa-cog fa-spin ml-1"></i>
                  </span>
                )
              }
              {
                !loading
                && (
                  <span>
                    create event
                    <i className="fas fa-plus ml-1"></i>
                  </span>
                )
              }
            </button>
          </div>
        </div>
        <div className="p-2 bg-gray-100 rounded text-gray-600 mt-2">
          htttp://focusacademy.com/e/{newSlug}
        </div>
      </div>
    </div>
  );
}

export default CreateEvent;
