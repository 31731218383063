import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CL from './cl';
import EmailModal from './EmailModal';


function FocusPlan(props) {
  const { event, attendee } = props;
  const attendeeData = attendee && attendee.data ? attendee.data : {};
  const focusPlan = attendeeData.focusPlan || {
    engagement: {
      challenges: [],
      solutions: [],
      habits: [],
    },
    energy: {
      challenges: [],
      solutions: [],
      habits: [],
    },
    internal: {
      challenges: [],
      solutions: [],
      habits: [],
    },
    external: {
      challenges: [],
      solutions: [],
      habits: [],
    },
  };
  const [emailModal, setEmailModal] = useState(false);
  
  if (!attendeeData.attendeeKey) {
    return null;
  }
  const { attendeeKey } = attendeeData;

  return (
    <>
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      
      <pre className="hidden">{JSON.stringify(focusPlan, null, 2) }</pre>

      <CL key="engagement" event={event} attendeeKey={attendeeKey} cl="engagement" category="solutions" focusPlan={focusPlan} />
      <CL key="energy" event={event} attendeeKey={attendeeKey} cl="energy" category="solutions" focusPlan={focusPlan} />
      <CL key="internal" event={event} attendeeKey={attendeeKey} cl="internal" category="solutions" focusPlan={focusPlan} />
      <CL key="external" event={event} attendeeKey={attendeeKey} cl="external" category="solutions" focusPlan={focusPlan} />

    </div>

    <div className="mt-8">
      <div className="mx-auto text-center">
        <button
          type="button"
          className="focus:outline-none bg-gray-700 hover:bg-gray-800 text-white rounded px-3 py-2"
          onClick={() => {
            // sendMail
            setEmailModal(true);
          }}
        >
          E-mail your Focus Plan
        </button>
      </div>
    </div>

    {
      !!emailModal
      && <EmailModal setEmailModal={setEmailModal} event={event} attendee={attendee} focusPlan={focusPlan} />
    }

    </>
  );
}

FocusPlan.defaultProps = {
  attendee: null,
}


FocusPlan.propTypes = {
  event: PropTypes.object.isRequired,
  attendee: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
};

export default FocusPlan;
