import React from 'react';
import PropTypes from 'prop-types';
import twemoji from 'twemoji';
import linkifyHtml from 'linkifyjs/html';


function Sticky(props) {
  const { event } = props;
  const sticky = event && event.data && event.data.sticky
    ? event.data.sticky
    : null;


  if (sticky) {
    const { messageKey } = sticky;
    const messageCreator = sticky.userData;

    if (!messageKey || !sticky.message || !messageCreator.name) {
      return null;
    }

    var emojis = {
      ':thumbs-up:': '👍',
      ':thumbs-down:': '👎',
      ':clapping-hands:': '👏',
      ':folded-hands:': '🙏',
      ':waving-hand:': '👋',
      ':brain:': '🧠',
    };
    var re = new RegExp(Object.keys(emojis).join("|"),"gi");
    const messageWithEmoji = sticky.message.replace(re, function(matched){
      return emojis[matched];
    });
  
    const messageWithEMojis = twemoji.parse(messageWithEmoji, {
      folder: 'svg',
      ext: '.svg'
    });

    const messageWithLinks = linkifyHtml(messageWithEMojis, {
      target: '_blank',
      className: 'text-green-400 hover:underline'
    });

    return (
      <div className="py-2 bg-blue-700 mb-2">
        <div className="px-3 text-gray-700">
          <div className="text-sm lg:text-base" style={{ paddingLeft: '1.5rem', textIndent: '-1.5rem' }}>
            <span className="font-bold mr-2 text-gray-100">{ messageCreator.name }</span>
            <span className="text-gray-300">
              <span
                dangerouslySetInnerHTML={{
                  __html: messageWithLinks,
                }}
              />
            </span>
          </div>
        </div>
      </div>
    );
  }

  return null;
}


Sticky.defaultProps = {
  attendee: null,
}

Sticky.propTypes = {
  event: PropTypes.object.isRequired,
  attendee: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
};

export default Sticky;
