import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';

function AmazonPlayer(props) {
  const { event, demo } = props;

  const url = event && event.data && event.data.url && !demo
    ? event.data.url
    : 'https://fcc3ddae59ed.us-west-2.playback.live-video.net/api/video/v1/us-west-2.893648527354.channel.DmumNckWFTqz.m3u8';

  const helpInstructions = event && event.data && event.data.helpInstructions
    ? event.data.helpInstructions
    : null;
  
  const pageStatus = event && event.data && event.data.pageStatus
    ? event.data.pageStatus
    : 'liveSoon';

  const [videoNode, setVideoNode] = useState();
  const [player, setPlayer] = useState();
  const [liveLatency, setLiveLatency] = useState();

  useEffect(() => {
    if (videoNode) {

      const videoJsOptions = {
        techOrder: ["AmazonIVS"],
        autoplay: false,
        controls: true,
        fluid: true,
        aspectRatio: '16:9',
      };

      window.registerIVSTech(videojs);
      const createPlayer = videojs(videoNode, videoJsOptions, () => {
        console.log('onPlayerReady', createPlayer);
        createPlayer.src(url);
        setPlayer(createPlayer);
      });
    }
  }, [videoNode, url])

  useEffect(() => {
    const interval = setInterval(() => {
      if (player) {
        console.log(player.getIVSPlayer().core.state.liveLatency);
        if (!player.getIVSPlayer()) {
          return;
        }
        if (!player.getIVSPlayer().core) {
          return;
        }
        if (!player.getIVSPlayer().core.state) {
          return;
        }
        if (!player.getIVSPlayer().core.state.liveLatency) {
          return;
        }
        setLiveLatency(player.getIVSPlayer().core.state.liveLatency);
      }
    }, 1000 );

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [player])

  return (
    <div>
      <div className="relative aspect-ratio-box">

        <video
          id="my-player"
          class="video-js vjs-default-skin vjs-big-play-centered"
          controls
          width="100%"
          height="auto"
          ref={(node) => {
            setVideoNode(node);
          }}
        />
      </div>
      <div className="p-3 bg-gray-800 flex items-center">
        <div className="text-gray-600 text-sm">
          {
            !!helpInstructions
            && pageStatus === 'liveNow'
            && (
              <div dangerouslySetInnerHTML={{ __html: helpInstructions }} />
            )
          }
          {
            !helpInstructions
            && pageStatus === 'liveNow'
            && (
              <>
                Voor technische ondersteuning mail naar: <a className="font-bold hover:underline" href="mailto:sandra@focusacademy.com">sandra@focusacademy.com</a>
              </>
            )
          }
        </div>
        <div className="ml-auto flex">
          {
            !!liveLatency
            && (
              <div className="mr-2 ml-auto text-xs px-2 py-1 text-gray-500">
                {
                  liveLatency.toFixed(1)
                } 
                {' s'}
              </div>
            )
          }
          <button
            className="text-xs px-2 py-1 border text-gray-500 border-gray-600 rounded hover:border-gray-400 hover:text-gray-300 focus:outline-none"
            onClick={() => {
              window.location.reload(false);
            }}
          >
            refresh
          </button>
        </div>
      </div>
    </div>
  );
}

AmazonPlayer.propTypes = {
  event: PropTypes.object.isRequired,
};

export default AmazonPlayer;